<template>
  <div>
    <div class="flex flex-col items-center justify-center w-full h-full pb-20">
      <section
        class="flex flex-col items-center w-full justify-center xl:w-[100%]"
      >
        <div
          class="mt-[18px] w-full xl:w-[100%] flex justify-center items-center gap-4"
        >
          <div class="w-full bg-white border md:rounded-3xl">
            <div class="flex flex-col justify-between w-full px-5 py-2 mx-auto">
              <div class="flex w-full items-center justify-between mt-2">
                <div class="justify-end flex flex-row items-center gap-2">
                  <div class="location-dropdown relative md:max-w-[200px]">
                    <div class="relative">
                      <input
                        type="text"
                        v-model="locationSearch"
                        placeholder="Locations"
                        @click="toggleStoreDropdown($event)"
                        class="w-full pt-2 pr-8 border border-gray-300 rounded text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder-gray-600 font-medium"
                      />
                      <button
                        @click="toggleStoreDropdown($event)"
                        class="absolute inset-y-0 right-0 flex items-center px-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      ref="showStoreDropdown"
                      v-if="showStoreDropdown"
                      class="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-50 max-h-60 max-w-[800px] overflow-y-auto px-5 w-[400px] max-md:w-[300px]"
                    >
                      <div
                        v-for="profile in filteredStores"
                        :key="profile.wid"
                        class="platform p-2"
                      >
                        <div class="flex items-center gap-2">
                          <input
                            type="checkbox"
                            v-model="profile.checked"
                            @change="handleLocationChange(profile)"
                          />
                          <div class="flex flex-col">
                            <span class="font-medium text-sm">{{ profile.sub_locality }}</span>
                            <div class="flex gap-1">
                              <span v-if="profile.city" class="text-xs">{{ profile.city }}</span>
                              <span v-if="profile.state" class="text-xs">{{ `, ${profile.state}` }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="filteredStores.length === 0"
                        class="p-4 text-center text-gray-500"
                      >
                        No locations found
                      </div>
                    </div>
                  </div>
                  <v-btn
                    color="primary"
                    outlined
                    small
                    @click="showLocationLatestReview"
                    :loading="isOpeningLatestReview"
                    class="mr-2"
                  >
                  <v-icon left small>mdi-chart-box</v-icon>
                   Latest Recieved Reviews 
                  </v-btn>
                  <div class="flex items-center gap-1">
                    <span class="text-sm text-gray-600">Needs Action</span>
                    <v-switch
                      v-model="needsAction"
                      :loading="isNeedsActionLoading"
                      @change="toggleNeedsAction"
                      dense
                      class="items-center"
                    ></v-switch>
                    <span class="text-sm text-gray-600 ml-2">Sort by:</span>
                    <div class="relative w-[120px]">
                      <select
                        v-model="sortOrder"
                        @change="handleSortChange"
                        class="w-full px-3 py-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-500 appearance-none bg-white cursor-pointer pr-8"
                      >
                        <option value="desc">Newest</option>
                        <option value="asc">Oldest</option>
                      </select>
                      <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <svg
                          class="w-4 h-4 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <v-btn
                    color="primary"
                    outlined
                    small
                    @click="showReport"
                    :loading="isGeneratingReport"
                    class="mr-2"
                  >
                    <v-icon left small>mdi-chart-box</v-icon>
                    GMB Reviews Insights
                  </v-btn>
                  <v-btn
                    color = "primary"
                    outlined
                    small
                    @click="showWelcomePopup = true"
                    class="ml-2"
                  >
                    Auto Respond
                  </v-btn>
                  <div v-if="showWelcomePopup" class="popup-overlay">
                    <div class="popup-content">
                      <button @click="closePopup" class="close-button">✖</button>
                      <h3 class="text-xl font-bold mb-4">Auto Respond Setting</h3>
                      <div class="relative flex flex-col">
                        <label>
                          <input type="checkbox" v-model="autoRespond" @change="updateAutoRespond" />
                          Enable Auto Respond Review
                        </label>
                        <label v-if="autoRespond">
                          <input type="checkbox" v-model="autoRespondNegative.value" @change="updateNegativeRespond" />
                          Enable Auto Respond Negative Review
                        </label>
                        <div v-if="autoRespondNegative.value" class="mt-4">
                          <span class="font-medium">Select Reply Type:</span>
                          <div>
                            <label>
                              <input type="radio" v-model="autoRespondNegative.type" @change="updateNegativeRespond" value="ai" />
                              Ai generated reply
                            </label>
                            <label>
                              <input type="radio" v-model="autoRespondNegative.type" value="manual" />
                              Manual reply
                            </label>
                          </div>
                        </div>
                        <div v-if="autoRespondNegative.value && autoRespondNegative.type === 'manual'" class="mt-4">
                          <textarea
                            v-model="autoRespondNegative.reply"
                            placeholder="Type your reply..."
                            class="border rounded p-2 w-full resize-none"
                            rows="2"
                          ></textarea>
                          <button
                            @click="submitManualReply"
                            :disabled="!autoRespondNegative.reply"
                            class="ml-2 bg-blue-600 text-white rounded px-4 py-2 cursor-pointer"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="relative flex flex-col md:flex-row items-start md:items-center justify-between mx-4"
              >
                <!-- Sort dropdown on the left -->
                <div class="flex items-center gap-2 w-full md:w-auto">
               
                </div>

                <!-- Star rating filters on the right -->
                <div class="star-rating-selection bg-white rounded-lg">
                  <div class="flex items-center gap-2 flex-wrap justify-end">
                    <!-- All Reviews Button -->
                    <button
                      @click="filterByRating(null)"
                      :class="[
                        'px-4 py-2 rounded-full text-sm font-medium transition-colors',
                        selectedRating === null
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200',
                      ]"
                    >
                      All
                    </button>

                    <!-- Star Rating Buttons -->
                    <button
                      v-for="rating in 5"
                      :key="rating"
                      @click="filterByRating(rating)"
                      :class="[
                        'flex items-center gap-1 px-4 py-2 rounded-full text-sm font-medium transition-colors justify-center',
                        selectedRating === ratingMap[rating]
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200',
                      ]"
                    >
                      {{ rating }}
                      <svg
                        class="w-4 h-4"
                        :class="selectedRating === rating ? 'text-blue-600' : 'text-yellow-400'"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <div class="grid grid-cols-1 gap-6 mt-6 px-4">
                  <div
                    v-if="
                      !isLoading && (!allReviews || allReviews?.length == 0)
                    "
                    class="text-center py-8 text-gray-500"
                  >
                    No reviews found
                  </div>
                </div>
                <!-- Reviews -->
                <div
                  v-if="isLoading"
                  class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6 mx-auto w-full"
                >
                  <v-skeleton-loader
                    v-for="n in 2"
                    :key="n"
                    class="bg-white rounded-lg shadow-md mx-4"
                    type="paragraph,list-item-avatar,article"
                    :boilerplate="true"
                  >
                  </v-skeleton-loader>
                </div>
                <div
                  v-if="!isLoading"
                  class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 mt-6 px-4"
                >
                  <div
                    v-for="review in allReviews"
                    :key="review.reviewId"
                    class="bg-white rounded-lg shadow-md p-6 border border-gray-200"
                  >
                    <div
                      class="flex items-center gap-2 mb-2 pb-3 border-b border-gray-100"
                    >
                      <!-- <img
                        :src="gmb_logo"
                        alt="Google Business Profile"
                        class="w-6 h-6 object-contain"
                      /> -->
                      <span class="text-xs md:text-sm font-medium text-gray-700">{{
                        review.sub_locality
                      }}</span>
                    </div>
                    <!-- Reviewer Info -->
                    <div class="flex items-center gap-3 mb-4">
                      <img
                        v-if="review.profilePhotoUrl"
                        :src="review.profilePhotoUrl"
                        alt="profile"
                        crossorigin="anonymous"
                        referrerpolicy="no-referrer"
                        class="w-10 h-10 rounded-full object-cover"
                      />
                      <img v-else  class="w-10 h-10 rounded-full object-cover" src="@/assets/img/mgacc.svg"  />
                      <div>
                        <h3 class="font-medium text-gray-900 text-sm md:text-base">
                          {{ review.displayName }}
                        </h3>
                        <div
                          class="flex items-start sm:items-center gap-0 md:gap-2 flex-col md:flex-row"
                        >
                          <!-- Star Rating -->
                          <div class="flex">
                            <span v-for="index in 5" :key="index">
                              <svg
                                :class="
                                  index <= review.starRating
                                    ? 'text-yellow-400'
                                    : 'text-gray-300'
                                "
                                class="w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                />
                              </svg>
                            </span>
                          </div>
                          <!-- Date -->
                          <!-- <span class="text-xs text-gray-500">
                            {{ formatDate(review.createTime) }}
                          </span> -->
                          <div class="text-xs text-gray-500">
                            <span>{{ formatTime(review.updateTime) }}</span>
                            <span class="mx-1">•</span>
                            <span>{{ formatDate(review.updateTime) }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Review Comment -->
                    <div class="flex flex-col gap-2">
                      <div class="flex flex-col gap-1 items-start">
                          <div class="text-gray-700 text-sm md:text-base">
                            <p
                              v-if="
                                review.comment?.length <= 60 ||
                                review.showFullComment
                              "
                            >
                              {{ review.comment }}
                            </p>
                            <p v-else>
                              {{ review.comment.slice(0, 60) }}...
                              <button
                                @click="toggleComment(review)"
                                class="text-blue-600 hover:text-blue-700 text-sm ml-1"
                              >
                                See more
                              </button>
                            </p>
                            <p
                              v-if="
                              review.comment?.length > 60 &&
                                review.showFullComment
                              "
                            >
                              <button
                                @click="toggleComment(review)"
                                class="text-blue-600 hover:text-blue-700 text-sm mt-1"
                              >
                                See less
                              </button>
                            </p>
                        </div>
                        <div class="flex items-center gap-1">
                          <div v-if="!review.reviewReply.comment" class="flex items-center gap-2">
                            <!-- Manual Reply Button -->
                            <v-btn
                              @click="startNewReply(review)"
                              class="m-0 p-0 -mt-1 text-gray-600"
                              icon
                              title="Write manual reply"
                            >
                              <v-icon>mdi-reply</v-icon>
                            </v-btn>
                            <button
                              @click="startNewReply(review)"
                              class="text-blue-600 hover:text-blue-700 text-xs"
                            >
                              Reply
                            </button>

                            <!-- Divider -->
                            <div class="h-4 w-px bg-gray-300 mx-1"></div>
                            <button
                              @click="openSuggestionsDrawer(review)"
                              class="text-blue-600 hover:text-blue-700 text-xs"
                            >
                              Suggest Reply
                            </button>
                          </div>
                        </div>
                      </div>
                      <div v-if="review.reviewReply?.isNewReply" class="mt-2">
                        <textarea
                          v-model="review.reviewReply.newComment"
                          rows="3"
                          class="w-full px-3 py-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm md:text-base focus:border-blue-500"
                          placeholder="Type your reply..."
                        ></textarea>
                        <div class="flex justify-end gap-2 mt-2">
                          <button
                            @click="cancelNewReply(review)"
                            class="px-3 py-1 text-sm text-gray-600 hover:text-gray-800 rounded-md hover:bg-gray-100"
                          >
                            Cancel
                          </button>
                          <button
                            @click="submitNewReply(review)"
                            class="px-3 py-1 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50 relative"
                            :disabled="review.isSubmittingNew"
                          >
                            <span v-if="!review.isSubmittingNew">
                              Update Reply
                            </span>
                            <v-progress-circular
                              v-else
                              indeterminate
                              size="16"
                              width="2"
                              color="white"
                              class="mx-4"
                            ></v-progress-circular>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="
                        review.reviewReply?.comment 
                      "
                      class="mt-4 pl-4 border-l-2 border-gray-200"
                    >
                      <div class="flex items-start gap-2">
                        <div
                          class="w-1 h-1 flex-none rounded-full bg-purple-500 flex items-center justify-center text-white text-lg"
                        >
                          V
                        </div>
                        <div class="flex-grow overflow-wrap">
                          <!-- Business Name and Owner Badge -->
                          <div class="flex items-center justify-between">
                            <div class="flex items-center gap-2">
                              <span class="font-medium text-sm md:text-base">{{
                                review.locationTitle || "Business Owner"
                              }}</span>
                              <span class="text-sm text-gray-500">Owner</span>
                            </div>
                            <!-- Action Buttons -->
                            <div class="flex items-center justify-end gap-1">
                              <button
                                @click="startEditingReply(review)"
                                class="p-1.5 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
                                title="Edit reply"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                  />
                                </svg>
                              </button>
                              <button
                                @click="deleteReply(review)"
                                class="p-1.5 text-gray-500 hover:text-red-600 rounded-full hover:bg-gray-100 relative"
                                title="Delete reply"
                                :disabled="deletingReplyId === review.reviewId"
                              >
                                <v-progress-circular
                                  v-if="deletingReplyId === review.reviewId"
                                  indeterminate
                                  size="16"
                                  width="2"
                                  color="red"
                                  class="absolute inset-0 m-auto"
                                ></v-progress-circular>
                                <svg
                                  v-else
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>

                          <!-- Reply Time -->
                          <div class="text-xs text-gray-500 mt-1">
                            <span>{{
                              formatTime(review.reviewReply.updateTime)
                            }}</span>
                            <span class="mx-1">•</span>
                            <span>{{
                              formatDate(review.reviewReply.updateTime)
                            }}</span>
                          </div>
                          <!-- Normal Reply View -->
                          <div
                            v-if="!review.reviewReply.isEditing"
                            class="mt-1 text-gray-700"
                          >
                            <p
                              class="text-sm md:text-base"
                              v-if="
                                review.reviewReply.comment?.length <= 100 ||
                                review.reviewReply.showFullComment
                              "
                            >
                              {{ review.reviewReply.comment }}
                            </p>
                            <p v-else>
                              {{ review.reviewReply.comment?.slice(0, 100) }}...
                              <button
                                @click="toggleReplyComment(review)"
                                class="text-blue-600 hover:text-blue-700 text-sm ml-1"
                              >
                                See more
                              </button>
                            </p>
                            <p
                              v-if="
                                review.reviewReply.comment?.length > 100 &&
                                review.reviewReply.showFullComment
                              "
                            >
                              <button
                                @click="toggleReplyComment(review)"
                                class="text-blue-600 hover:text-blue-700 text-sm mt-1"
                              >
                                See less
                              </button>
                            </p>
                          </div>
                          <!-- Edit Form -->
                          <div v-else class="mt-2">
                            <textarea
                              v-model="review.reviewReply.editedComment"
                              rows="3"
                              class="w-full px-3 py-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="Edit your reply..."
                            ></textarea>
                            <div class="flex justify-end gap-2 mt-2">
                              <button
                                @click="cancelEditingReply(review)"
                                class="px-3 py-1 text-sm text-gray-600 hover:text-gray-800 rounded-md hover:bg-gray-100"
                              >
                                Cancel
                              </button>
                              <button
                                @click="submitReplyEdit(review)"
                                class="px-3 py-1 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50 relative"
                                :disabled="updatingReplyId === review.reviewId"
                              >
                                <span v-if="updatingReplyId !== review.reviewId">
                                  Update Reply
                                </span>
                                <v-progress-circular
                                  v-else
                                  indeterminate
                                  size="16"
                                  width="2"
                                  color="white"
                                  class="mx-4"
                                ></v-progress-circular>
                              </button>
                            </div>
                          </div>

                          <!-- Reply Comment -->
                          <!-- <p class="mt-1 text-sm md:text-lg text-gray-700">
                            {{ review.reviewReply.comment }}
                          </p> -->
                        </div>
                      </div>
                    </div>
                    <div v-if="review.needsAction" class="flex items-center gap-2 mt-2">
                      <label class="flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          :checked="review.actionTaken"
                          :disabled="review.isActionLoading"
                          @change="handleActionTaken(review)"
                          class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                        />
                        <span 
                          :class="[
                            'ml-2 text-sm',
                            review.actionTaken ? 'text-green-600 font-medium' : 'text-gray-600'
                          ]"
                        >
                          {{ review.actionTaken ? 'Action Taken' : 'Mark as Actioned' }}
                        </span>
                      </label>
                      <v-progress-circular
                        v-if="review.isActionLoading"
                        indeterminate
                        size="16"
                        width="2"
                        color="primary"
                        class="ml-2"
                      ></v-progress-circular>
                    </div>
                    <div v-if="review.showSuggestions" class="mt-4 border-t pt-4">
                      <div class="flex items-center justify-between mb-3">
                        <h4 class="text-base font-medium">Suggested Replies</h4>
                        <button 
                          @click="review.showSuggestions = false"
                          class="text-gray-500 hover:text-gray-700"
                        >
                          <v-icon small>mdi-close</v-icon>
                        </button>
                      </div>
                      
                      <div v-if="review.isLoadingSuggestions" class="flex justify-center py-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      
                      <div v-else-if="review.suggestedReplies?.length > 0" class="space-y-3">
                        <div 
                          v-for="(reply, index) in review.suggestedReplies" 
                          :key="index"
                          class="p-3 border rounded bg-gray-50 flex justify-between items-start gap-2"
                        >
                          <p class="text-sm text-gray-700">{{ reply }}</p>
                          <v-btn
                            small
                            color="primary"
                            @click="useSuggestedReply(review, reply)"
                            :loading="review.sendingReply"
                            class="mt-0 flex-shrink-0"
                          >
                            Use
                          </v-btn>
                        </div>
                      </div>
                      
                      <div v-else class="text-center py-3 text-gray-500">
                        No suggestions available
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <div class="flex justify-center mt-6">
          <div class="flex items-center gap-4">
            <button
              @click="changePage(currentPage - 1)"
              :disabled="currentPage === 1"
              class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
            >
              Previous
            </button>

            <div class="flex items-center gap-2">
              <span class="text-sm text-gray-700">Page</span>
              <span class="font-medium">{{ currentPage }}</span>
              <span class="text-sm text-gray-700">of</span>
              <span class="font-medium">{{ totalPages }}</span>
            </div>

            <button
              @click="changePage(currentPage + 1)"
              :disabled="currentPage === totalPages"
              class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
            >
              Next
            </button>

            <div class="relative">
              <select
                v-model="pageSize"
                @change="handlePageSizeChange"
                class="appearance-none px-3 py-2 pr-8 text-sm border border-gray-300 rounded-md cursor-pointer"
              >
                <option :value="12">12 per page</option>
                <option :value="20">20 per page</option>
                <option :value="50">50 per page</option>
              </select>
              <div
                class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none"
              >
                <svg
                  class="w-4 h-4 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  collection,
  onSnapshot,
  query,
  where,
  getDoc,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";
import { db } from "@/utils/firebase.utils";
import { ai } from "@cloudinary/url-gen/qualifiers/format";

export default {
  data() {
    return {
      stores: [],
      showStoreDropdown: false,
      selectedWID: null,
      selectedWIDs: [],
      metricsData: {},
      isLoading: false,
      dateRanges: ["Last 7 days", "Last 28 days", "Last 60 days"],
      selectedDateRange: "Last 7 days",
      sortBy: "location",
      sortDesc: false,
      locationTitles: {},
      gmb_logo: "",
      allReviews: [],
      isSubmitting: false,
      selectedRating: null,
      currentPage: 1,
      pageSize: 12,
      totalItems: 0,
      totalPages: 1,
      locationSearch: "",
      autoRespond: false,
      autoRespondNegative: {
        value: false,
        type: "",
        reply: "",
      },
      isAutoRespondLoading: false,
      needsAction: this.$route.query.action === 'true',
      isNeedsActionLoading: false,
      ratingMap : {
        1: "ONE",
        2: "TWO",
        3: "THREE",
        4: "FOUR",
        5: "FIVE",
      },
      showSuggestionsDrawer: false,
      isLoadingSuggestions: false,
      suggestedReplies: [],
      currentReview: null,
      sendingReply: false,
      deletingReplyId: null,
      updatingReplyId: null,
      sortOrder: 'desc',
      isGeneratingReport: false,
      isOpeningLatestReview: false,
      showWelcomePopup: false,
    };
  },
  computed: {
    filteredStores() {
      if (!this.locationSearch) {
        return this.stores;
      }

      const searchTerm = this.locationSearch.toLowerCase();
      return this.stores.filter(
        (store) =>
          store.name?.toLowerCase().includes(searchTerm) ||
          store.sub_locality?.toLowerCase().includes(searchTerm) ||
          store.city?.toLowerCase().includes(searchTerm)
      );
    },
    filteredReviews() {
      if (!this.selectedRating) {
        return this.allReviews;
      }
      return this.allReviews.filter(
        (review) => review.starRating === this.selectedRating
      );
    },

    displayedReviews() {
      if (this.selectedRating === null) {
        return this.allReviews;
      }

      return this.allReviews.filter((review) => {
        // Convert string ratings to numbers if necessary
        const reviewRating =
          typeof review.starRating === "string"
            ? parseInt(review.starRating)
            : review.starRating;

        // console.log("reviewRating:", reviewRating);
        return reviewRating === this.selectedRating;
      });
    },

    ratingCounts() {
      return this.allReviews.reduce((acc, review) => {
        const rating =
          typeof review.starRating === "string"
            ? parseInt(review.starRating)
            : review.starRating;

        console.log("rating", rating);
        acc[rating] = (acc[rating] || 0) + 1;
        return acc;
      }, {});
    },
  },
  watch: {
    // Reset pagination when filters change
    selectedWIDs: {
      handler() {
        this.currentPage = 1;
        this.allReviews = [];
        if(this.selectedWIDs?.length > 0) {
          this.fetchReviewsForAllLocations();
        }
      },
    },
    needsAction: {
      handler() {
        this.currentPage = 1;
        this.allReviews = [];
        if(this.selectedWIDs?.length > 0) {
          this.fetchReviewsForAllLocations();
        }
      }
    },
    '$route.query.action': {
      immediate: true,
      handler(newValue) {
        this.needsAction = newValue === 'true';
        if (this.needsAction) {
          this.allReviews = [];
          if(this.selectedWIDs?.length > 0) {
            this.fetchReviewsForAllLocations();
          }
        }
      }
    }
  },
  methods: {
    async handleSortChange() {
      this.currentPage = 1; // Reset to first page when sorting changes
      if(this.selectedWIDs?.length > 0) {
        await this.fetchReviewsForAllLocations();
      }
    },
    toggleStoreDropdown(event) {
      event.stopPropagation();
      this.showStoreDropdown = !this.showStoreDropdown;
    },

    handleClickOutside(event) {
      const dropdown = this.$refs.showStoreDropdown;
      const input = event.target.closest(".location-dropdown");
      if (!input && dropdown && !dropdown.contains(event.target)) {
        this.showStoreDropdown = false;
      }
    },
    async changePage(newPage) {
      if (newPage >= 1 && newPage <= this.totalPages) {
        this.currentPage = newPage;
        await this.fetchReviewsForAllLocations();
        // Scroll to top of reviews section
        this.$nextTick(() => {
          window.scrollTo({
            top: this.$refs.reviewsSection?.offsetTop,
            behavior: "smooth",
          });
        });
      }
    },
    async handlePageSizeChange() {
      this.currentPage = 1; // Reset to first page when changing page size
      await this.fetchReviewsForAllLocations();
    },
    getLocationIdForWid(wid) {
      const store = this.stores.find((store) => store.wid === wid);
      return store?.locationId || "";
    },
    getStoreForWid(wid) {
      const store = this.stores.find((store) => store.wid === wid);
      return store || "";
    },

    async filterByRating(rating) {
      this.currentPage = 1;
      const ratingMap = {
        1: "ONE",
        2: "TWO",
        3: "THREE",
        4: "FOUR",
        5: "FIVE",
      };
      // this.selectedRating = rating;
      this.selectedRating = ratingMap[rating] || null;
      if(this.selectedWIDs?.length > 0) {
        await this.fetchReviewsForAllLocations();
      }
    },

    getReviewCountByRating(rating) {
      return this.ratingCounts[rating] || 0;
    },
    clearRatingFilter() {
      this.selectedRating = null;
    },
    async handleLocationChange(profile) {
      if (profile.checked) {
        this.selectedWIDs.push(profile.wid);
      } else {
        this.selectedWIDs = this.selectedWIDs.filter(
          (wid) => wid !== profile.wid
        );
      }
      this.selectedWID = profile.wid;
      this.showStoreDropdown = false;
      this.currentPage = 1; // Reset to first page
      if(this.selectedWIDs?.length > 0) {
        await this.fetchReviewsForAllLocations();
      }
    },
    startEditingReply(review) {
      if (review.reviewReply) {
        this.$set(review.reviewReply, "isEditing", true);
        this.$set(
          review.reviewReply,
          "editedComment",
          review.reviewReply.comment
        );
        // console.log("Editing started:", review.reviewReply.isEditing);
      }
    },
    startNewReply(review) {
      if (!review.reviewReply.comment) {
        this.$set(review.reviewReply, "isNewReply", true);
        this.$set(review.reviewReply, "newComment", "");
        // console.log("Reply started:", review.reviewReply.isNewReply);
      }
    },

    async deleteReply(review) {
      try {
        if (review.reviewReply?.comment) {
          this.$confirm.show({
            message: "Are you sure you want to delete this reply?",
            onConfirm: async () => {
              this.deletingReplyId = review.reviewId;
              const updateReply = httpsCallable(functions, "GmbReviewsManagement");
              let payload = {
                uid: this.currentUID || this.uid,
                wid: review.wid,
                account: `accounts/${review.accountId}`,
                location: `locations/${review.locationId}`,
                reviewId: review.reviewId,
                action: "delete_gmb_review",
              };
              let result = await updateReply(payload);
              if (result.data?.success) {
                this.$snackbar.show("Reply deleted successfully");
                this.$set(review, 'reviewReply', {
                  isNewReply: "",
                  newComment: "",
                  comment: "",
                  updateTime: null,
                  showFullComment: false,
                });
              } else if (result.data.error) {
                this.$snackbar.show({
                  message: result.data.error,
                  color: "error",
                });
              }
              this.deletingReplyId = null;
            },
          });
        }
      } catch (error) {
        console.error("Error deleting reply:", error);
        this.deletingReplyId = null;
      }
    },

    cancelNewReply(review) {
      if (!review.reviewReply.comment) {
        this.$set(review.reviewReply, "isNewReply", false);
        this.$set(review.reviewReply, "newComment", "");
      }
    },
    cancelEditingReply(review) {
      if (review.reviewReply) {
        this.$set(review.reviewReply, "isEditing", false);
        this.$set(
          review.reviewReply,
          "editedComment",
          review.reviewReply.comment
        );
      }
    },
    async submitReplyEdit(review) {
      if (!review.reviewReply?.editedComment?.trim()) {
        return;
      }
      
      this.updatingReplyId = review.reviewId;
      try {
        const updateReply = httpsCallable(functions, "GmbReviewsManagement");
        let payload = {
          uid: this.currentUID || this.uid,
          wid: review.wid,
          account: `accounts/${review.accountId}`,
          location: `locations/${review.locationId}`,
          comment: review.reviewReply.editedComment,
          reviewId: review.reviewId,
          action: "update_gmb_review",
        };
        
        let result = await updateReply(payload);
        
        if (result.data?.success) {
          this.$snackbar.show("Reply updated successfully");
          // review.reviewReply.comment = review.reviewReply.editedComment;
          // review.reviewReply.updateTime = result.data.data.updateTime;
          // review.reviewReply.isEditing = false;
          const index = this.allReviews.findIndex(r => r.reviewId === review.reviewId);
          if (index !== -1) {
            this.$set(this.allReviews, index, {
              ...review,
              reviewReply: {
                ...review.reviewReply,
                comment: review.reviewReply.editedComment,
                updateTime: new Date().toISOString(),
                isEditing: false,
              }
            });
          }

          review.reviewReply.updateStatus = {
            success: true,
            message: "Reply updated successfully",
          };

          setTimeout(() => {
            review.reviewReply.updateStatus = null;
          }, 3000);
        } else {
          throw new Error(result.data.error || "Failed to update reply");
        }
      } catch (error) {
        console.error("Error updating reply:", error);
        this.$toast.error("Failed to update reply. Please try again.");
      } finally {
        this.updatingReplyId = null;
      }
    },
    async submitNewReply(review) {
      if (!review.reviewReply?.newComment?.trim()) {
        return;
      }
      
      this.$set(review, 'isSubmittingNew', true);
      try {
        const updateReply = httpsCallable(functions, "GmbReviewsManagement");
        let payload = {
          uid: this.currentUID || this.uid,
          wid: review.wid,
          account: `accounts/${review.accountId}`,
          location: `locations/${review.locationId}`,
          comment: review.reviewReply.newComment,
          reviewId: review.reviewId,
          action: "update_gmb_review",
        };
        
        let result = await updateReply(payload);
        
        if (result.data?.success) {
          this.$snackbar.show("Replied successfully");
          // review.reviewReply.comment = review.reviewReply.newComment;
          // review.reviewReply.updateTime = result.data.data.updateTime;
          // review.reviewReply.isNewReply = false;
          const index = this.allReviews.findIndex(r => r.reviewId === review.reviewId);
          if (index !== -1) {
            this.$set(this.allReviews, index, {
              ...review,
              reviewReply: {
                ...review.reviewReply,
                comment: review.reviewReply.newComment,
                updateTime: new Date().toISOString(),
                isNewReply: false,
              },
              showSuggestions: false
            });
          }

          review.reviewReply.updateStatus = {
            success: true,
            message: "Reply updated successfully",
          };

          setTimeout(() => {
            review.reviewReply.updateStatus = null;
          }, 3000);
        } else {
          throw new Error(result.data.error || "Failed to update reply");
        }
      } catch (error) {
        console.error("Error updating reply:", error);
        this.$toast.error("Failed to update reply. Please try again.");
      } finally {
        this.$set(review, 'isSubmittingNew', false);
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },

    formatTime(dateString) {
      return new Date(dateString).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
    },
    async fetchGMBLogo() {
      if (this.gmb_logo) {
        return;
      }
      try {
        const platformDoc = await getDoc(
          doc(db, "fly_platforms", "google_business_profile")
        );
        if (platformDoc.exists()) {
          const logoUrl = platformDoc.data().logo_url;
          this.gmb_logo = logoUrl;
        }
      } catch (error) {
        console.error("Error fetching platform logo:", error);
      }
    },
    formatTimeAgo(dateString) {
      const date = new Date(dateString);
      const now = new Date();
      const diffMinutes = Math.floor((now - date) / (1000 * 60));

      if (diffMinutes < 60) return `${diffMinutes} mins ago`;
      if (diffMinutes < 1440)
        return `${Math.floor(diffMinutes / 60)} hours ago`;
      return `${Math.floor(diffMinutes / 1440)} days ago`;
    },

    isNewReview(dateString) {
      const date = new Date(dateString);
      const now = new Date();
      const diffHours = (now - date) / (1000 * 60 * 60);
      return diffHours < 24; // Consider reviews less than 24 hours old as new
    },

    toggleComment(review) {
      review.showFullComment = !review.showFullComment;
    },

    toggleReplyComment(review) {
      if (review.reviewReply) {
        review.reviewReply.showFullComment =
          !review.reviewReply.showFullComment;
      }
    },
    closeProfileDropdown() {
      if (this.showStoreDropdown) {
        this.showStoreDropdown = false;
      }
    },

    async fetchStores() {
      const uid = this.currentUID;
      const storeRef = collection(db, "users", uid, "fly_workspaces");
      onSnapshot(storeRef, async (querySnapshot) => {
        this.stores = [];
        this.selectedWIDs = [];
        
        querySnapshot.forEach((doc) => {
          let storeData = doc.data();
          if (storeData.storeWorkspace && 
              storeData.admin === this.WID && 
              storeData.connected_apps?.includes("google_business_profile")) {
            storeData.wid = doc.id;
            storeData.type = "store";
            storeData.checked = true;
            this.stores.push(storeData);
            this.selectedWIDs.push(doc.id);
          }
        });
        
        if (this.selectedWIDs.length > 0) {
          await this.fetchReviewsForAllLocations();
        }
      });
    },

    handleImageError(e) {
      // Fallback to default avatar if image fails to load
      e.target.src = "path/to/default-avatar.png"; // Add your default avatar image
    },
    async fetchReviewsForAllLocations() {
      try {
        this.isLoading = true;
        // this.this.selectedWIDs = ['8XZNiRkKSuJth6acQNlA', 'ALte7VUzs3ps7nNqunix']
        console.log("This is the selected WIDs", this.selectedWIDs);

        // Fetch all connected apps data in parallel
        const fetchPromises = this.selectedWIDs.map(async (wid) => {
          const connectedAppsRef = collection(
            db,
            "users",
            this.currentUID,
            "fly_workspaces",
            wid,
            "connected-apps"
          );
          return getDocs(
            query(
              connectedAppsRef,
              where("__name__", "==", "google_business_profile")
            )
          );
        });

        const gbpDocs = await Promise.all(fetchPromises);
        // Process all documents in parallel
        const accounts = gbpDocs.flatMap((gbpDoc, index) => {
          if (gbpDoc.empty) return [];
          const wid = this.selectedWIDs[index];
          const gbpData = gbpDoc.docs[0].data();
          const accountId = gbpData.accounts?.[0]?.id || "";
          return (gbpData.accounts || []).flatMap((account) => {
            const locationId = account.locations?.[0]?.name || "";
            return {
              wid,
              location: locationId,
              account: accountId,
            };
          });
        });

        if (accounts.length === 0) {
          this.allReviews = [];
          this.totalPages = 0;
          this.currentPage = 0;
          return;
        }

        const fetchGmbReviews = httpsCallable(
          functions,
          "GmbReviewsManagement"
        );
        const payload = {
          uid: this.currentUID || this.UID,
          accounts,
          action: "get_gmb_reviews",
          platform: "google_business_profile",
          page: this.currentPage,
          pageSize: this.pageSize,
          starRating: this.selectedRating,
          needsAction: this.needsAction,
          sort: this.sortOrder,
        };

        const res = await fetchGmbReviews(payload);

        if (!res.data?.success || !res.data?.data) {
          console.log('Error fetching reviews:', res.data?.error);
        }
        // Process pagination and reviews
        const { pagination, reviews } = res.data.data;
        Object.assign(this, {
          // currentPage: pagination.currentPage,
          pageSize: pagination.pageSize,
          totalItems: pagination.totalItems,
          totalPages: pagination.totalPages || 0,
          currentPage: pagination.totalPages === 0 ? 0 : pagination.currentPage,
        });
        // Map reviews with optimized object creation
        const ratingMap = { ONE: 1, TWO: 2, THREE: 3, FOUR: 4, FIVE: 5 };
        this.allReviews = reviews.map((review) => ({
          reviewId: review.reviewId,
          displayName: review.reviewer?.displayName || "Anonymous",
          profilePhotoUrl: review.reviewer?.profilePhotoUrl || "",
          starRating: ratingMap[review.starRating] || 0,
          comment: review.comment || "",
          sub_locality: this.getStoreForWid(review.wid)?.sub_locality || "",
          createTime: review.createTime,
          updateTime: review.updateTime,
          locationId: review.location_id,
          accountId: review.name.split("/")[1],
          locationTitle: review.gmb_location_name,
          showFullComment: false,
          wid: review.wid,
          reviewReply: review.reviewReply
            ? {
                comment: review.reviewReply.comment,
                updateTime: review.reviewReply.updateTime,
                showFullComment: false,
              }
            : {
                isNewReply: "",
                newComment: "",
              },
          needsAction: review.action_needed || false,
          actionTaken: review.action_taken || false,
          isActionLoading: false,
        }));
        console.log("this review ",this.allReviews);
      } catch (error) {
        console.error("Error fetching metrics:", error);
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async toggleAutoRespond() {
      try {
        this.isAutoRespondLoading = true;
        
        // Update for each selected workspace

          const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
          await updateDoc(workspaceRef, {
            gmb_auto_respond: this.autoRespond
          });

        
        this.$snackbar.show(`Auto respond ${this.autoRespond ? 'enabled' : 'disabled'} successfully`);
      } catch (error) {
        console.error('Error updating auto respond setting:', error);
        this.$alert.show('Failed to update auto respond setting');
        this.autoRespond = !this.autoRespond; // Revert the toggle if failed
      } finally {
        this.isAutoRespondLoading = false;
      }
    },
    async toggleNeedsAction() {
      try {
        this.isNeedsActionLoading = true;
        
        // Update URL without reloading the page
        const query = { ...this.$route.query };
        if (this.needsAction) {
          query.action = 'true';
        } else {
          delete query.action;
        }
        
        await this.$router.replace({ query });
        this.currentPage = 1; // Reset to first page when toggling
        if(this.selectedWIDs?.length > 0) {
          await this.fetchReviewsForAllLocations();
        }
      } catch (error) {
        console.error('Error toggling needs action filter:', error);
        this.$alert.show('Failed to update needs action filter');
        this.needsAction = !this.needsAction; // Revert the toggle if failed
      } finally {
        this.isNeedsActionLoading = false;
      }
    },
    async handleActionTaken(review) {
      try {
        // Set loading state for this specific review
        this.$set(review, 'isActionLoading', true);

        const payload = {
          uid: this.currentUID,
          reviewId: review.reviewId,
          locationId: review.locationId,
          accountId: review.accountId,
          wid: review.wid,
          action: "update_action_status"
        };

        // Call your Cloud Function
        const updateActionStatus = httpsCallable(functions, "GmbReviewsManagement");
        const response = await updateActionStatus(payload);

        if (response.data?.success) {
          // Update the review status locally
          this.$set(review, 'actionTaken', true);
          this.$snackbar.show('Review marked as actioned successfully');
        } else {
          throw new Error('Failed to update action status');
        }
      } catch (error) {
        console.error('Error updating action status:', error);
        this.$alert.show('Failed to update action status');
        // Revert the checkbox state
        this.$set(review, 'actionTaken', false);
      } finally {
        this.$set(review, 'isActionLoading', false);
      }
    },
    async getWorkspaceName(){
      const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
      const docSnap = await getDoc(workspaceRef);

      if (docSnap.exists()) {
        const name = docSnap.data().name;
        if(name!='default' && name!==undefined){
          return name;
        }
      }

      return "";
    },
    async getBusinessDescription(review) {
      try {
        const connectedAppsRef = doc(
          db,
          "users",
          this.currentUID,
          "fly_workspaces",
          review.wid,
          "connected-apps",
          "google_business_profile"
        );
        
        const docSnap = await getDoc(connectedAppsRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          // Navigate through the nested structure
          const description = data?.accounts?.[0]?.locations?.[0]?.description;
          return description || "";
        }
        
        return "";
      } catch (error) {
        console.error("Error fetching business description:", error);
        return "";
      }
    },
    async openSuggestionsDrawer(review) {
      // Set initial state
      this.$set(review, 'showSuggestions', true);
      this.$set(review, 'isLoadingSuggestions', true);
      this.$set(review, 'suggestedReplies', []);
      this.$set(review, 'sendingReply', false);

      try {
        console.log("Review ",review);
        let customer = review?.displayName || "";
        let companyName = await this.getWorkspaceName() || "";
        let businessDescription = await this.getBusinessDescription(review) || "";
        console.log("This is the company name ",businessDescription);
        const aiAction = httpsCallable(functions, 'aiActions');
        const result = await aiAction({
          operation: 'generateReviewReplies',
          rating: review.starRating,
          textContent: review.comment,
          customer,
          companyName,
          businessDescription
        });

        if (result.data?.result?.output?.reply) {
          this.$set(review, 'suggestedReplies', result.data.result.output.reply);
        } else {
          throw new Error('No suggestions received');
        }
      } catch (error) {
        console.error('Error getting suggestions:', error);
        this.$alert.show('Failed to get reply suggestions');
      } finally {
        this.$set(review, 'isLoadingSuggestions', false);
      }
    },
    async fetchAutoRespondStatus() {
      try {
        const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
        const workspaceDoc = await getDoc(workspaceRef);

        if (workspaceDoc.exists()) {
          const workspaceData = workspaceDoc.data();
          this.autoRespond = workspaceData.gmb_auto_respond || false; // Default to false if field is not present
          this.autoRespondNegative = workspaceData.gmb_negative_respond || {
            value: false,
            type: "",
            reply: "",
          };
          console.log("ro cadc i", workspaceData.gmb_auto_respond, workspaceData.gmb_negative_respond)
        } 
      } catch (error) {
        console.error("Error fetching auto-respond status:", error);
        this.autoRespond = false; // Ensure a default value on error
      }
    },

    async useSuggestedReply(review, reply) {
      if (review.sendingReply) return;

      this.$set(review, 'sendingReply', true);
      try {
        // Set up the reply
        this.$set(review.reviewReply, 'newComment', reply);
        
        // Use existing submit logic
        await this.submitNewReply(review);
        
        // Close suggestions on success
        this.$set(review, 'showSuggestions', false);
      } catch (error) {
        console.error('Error sending suggested reply:', error);
        this.$alert.show('Failed to send reply');
      } finally {
        this.$set(review, 'sendingReply', false);
      }
    },
    async showReport() {
      this.$router.push('/gmb-reviews-report')
    },
    async showLocationLatestReview() {
      this.$router.push('/gmb-no-locations')
    },
    async closePopup() {
      if(this.autoRespondNegative){
        if(!this.autoRespondNegative?.type){
          this.autoRespondNegative = {
              value: false,
              reply: "",
              type: "",
          };
        }else if(!this.autoRespondNegative?.reply && this.autoRespondNegative?.type==="manual"){
          await this.fetchAutoRespondStatus();
        }
      }
      this.showWelcomePopup = false;
    },
    async submitManualReply() {
      try {
        const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
        await updateDoc(workspaceRef, {
          gmb_negative_respond: {
            value: true,
            reply: this.autoRespondNegative.reply,
            type: "manual",
          },
        });
        await this.closePopup();
        this.$snackbar.show("Manual reply submitted successfully");
      } catch (error) {
        console.log("error ",error);
        this.$alert.show("Failed to submit manual reply");
      }
    },
    async updateAutoRespond() {
      try {
        const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
        if(this.autoRespond){
          await updateDoc(workspaceRef, {
            gmb_auto_respond: this.autoRespond,
          });
        }
        else{
          await updateDoc(workspaceRef, {
            gmb_auto_respond: this.autoRespond,
            gmb_negative_respond: {
              value: false,
              reply: "",
              type: "",
            },
          });
          this.autoRespondNegative = {
              value: false,
              reply: "",
              type: "",
            };
        }
        this.$snackbar.show(`Auto respond ${this.autoRespond ? 'enabled' : 'disabled'} successfully`);
      } catch (error) {
        console.error('Error updating auto respond setting:', error);
        this.$alert.show('Failed to update auto respond setting');
      }
    },
    async updateNegativeRespond() {
      try {
        const workspaceRef = doc(db, "users", this.currentUID, "fly_workspaces", this.WID);
        if(this.autoRespondNegative.value && this.autoRespondNegative.type){
          this.autoRespondNegative.reply = ""; 
          await updateDoc(workspaceRef, {
            gmb_negative_respond: this.autoRespondNegative,
          });
          this.$snackbar.show(`AI Auto respond enabled`);
        }else{
          await updateDoc(workspaceRef, {
            gmb_negative_respond: {
              value: false,
              reply: "",
              type: "",
            },
          });
        }
      } catch (error) {
        console.error('Error updating negative respond setting:', error);
        this.$alert.show('Failed to update negative respond setting');
      }
    },
  },
  mounted() {
    this.fetchGMBLogo();
    this.fetchStores();
    this.fetchAutoRespondStatus();
    document.addEventListener("click", this.handleClickOutside);
  },
};
</script>
<style scoped>
textarea {
  min-height: 80px;
  resize: vertical;
  border-color: #e5e7eb; /* Tailwind gray-300 */
  background-color: #ffffff;
  transition: all 0.15s ease-in-out;
}

textarea:focus {
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid rgb(145, 143, 143);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative; /* To position the close button */
  width: 30%;
}

.close-button {
  position: absolute;
  top : 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: red
}

.border {
  border-width: 1px;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.form-checkbox {
  @apply rounded border-gray-300;
}

.form-checkbox:checked {
  @apply bg-blue-600 border-blue-600;
}

.form-checkbox:disabled {
  @apply opacity-50 cursor-not-allowed;
}
.overflow-wrap{
  overflow-wrap: anywhere;
}
</style>
