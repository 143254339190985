<template>
  <div class="w-full mx-auto px-8 my-8 report-container">
    <div
      class="header flex flex-col sm:flex-row items-start max-sm:justify-start max-sm:gap-3 sm:justify-between sm:items-center"
    >
      <h1 class="text-xl max-sm:text-lg font-semibold">
        GMB Competitor Analysis
      </h1>
    </div>
    <div
      class="flex justify-between items-center border border-gray-300 rounded-lg shadow-lg mt-6 py-4 px-6"
    >
      <div class="gap-2 w-full md:w-1/3">
        <div class="relative">
          <v-text-field
            v-model="locationSearch"
            label="Locations"
            hide-details
            dense
            outlined
            @click="toggleStoreDropdown($event)"
            class="location-input"
            color="rgba(0, 0, 0, 0.6)"
            background-color="white"
            :style="{
              '& .v-label': {
                top: '6px',
                fontSize: '14px',
              },
            }"
          >
            <template v-slot:append>
              <button
                @click="toggleStoreDropdown($event)"
                class="absolute inset-y-0 right-0 flex items-center px-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
            </template>
          </v-text-field>
          <div
            ref="showStoreDropdown"
            v-if="showStoreDropdown"
            class="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-50 max-h-60 max-w-[800px] overflow-y-auto py-2 px-5"
          >
            <div
              v-for="profile in filteredStores"
              :key="profile.wid"
              class="platform p-2 hover:bg-gray-100 cursor-pointer"
              @click="selectStore(profile)"
              :class="{ 'bg-gray-200': selectedWID === profile.wid }"
            >
              <div class="flex items-center gap-2">
                <div class="flex flex-col">
                  <span
                    class="font-medium text-base"
                    v-if="profile.sub_locality"
                    >{{ profile.sub_locality }}</span
                  >
                  <span class="text-sm">{{ profile.city }}</span>
                  <span class="text-sm">{{ profile.state }}</span>
                </div>
              </div>
              <!-- <div class="flex items-center gap-2">
                <input
                  type="radio"
                  :value="profile.wid"
                  v-model="selectedWID"
                  @change="fetchStorePosts(profile)"
                />
                <div class="flex flex-col">
                  <span
                    class="font-medium text-base"
                    v-if="profile.sub_locality"
                    >{{ profile.sub_locality }}</span
                  >
                  <span class="text-sm">{{ profile.city }}</span>
                  <span class="text-sm">{{ profile.state }}</span>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div>
        <v-btn color="primary" class="px-6 rounded-full" @click="fetchData"
          >Fetch</v-btn
        >
      </div>
    </div>
    <div
      class="w-full"
      v-if="
        formattedCompetitors && Object.keys(formattedCompetitors).length > 0
      "
    >
      <div
        class="flex rounded-lg shadow-lg w-[100%] mx-auto my-8 bg-white border border-gray-300"
      >
        <div class="flex flex-col px-4 py-2 gap-4">
          <h2 class="text-lg font-semibold">Overview - {{ generatedDate }}</h2>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <!-- <div
              class="flex justify-between items-center border border-gray-300 rounded-lg p-4"
            >
              <h3 class="text-base font-semibold">All Keywords Avg.</h3>
              <div>
                <div
                  class="flex items-center gap-2 border border-gray-300 rounded-lg px-4 bg-gray-200"
                >
                  <span
                    class="text-base font-semibold border-r-2 border-gray-400 py-1 pr-4 bg-gray-200"
                    >4.3</span
                  >
                  <span class="text-sm text-green-500">0.6 ↑</span>
                </div>
              </div>
            </div> -->

            <div
              class="flex justify-between items-center border border-gray-300 rounded-lg p-4"
            >
              <h3 class="text-base font-semibold">Total Keywords</h3>
              <!-- <div class="flex items-center gap-2"> -->
              <span
                class="text-base font-semibold border border-gray-300 rounded-lg px-4 py-1 bg-gray-100 text-sky-700"
                >{{ keywords.length }}</span
              >
              <!-- </div> -->
            </div>

            <div
              class="flex justify-between items-center border border-gray-300 rounded-lg p-4"
            >
              <h3 class="text-base font-semibold">Your Avg. Rank</h3>
              <div class="flex items-center gap-2 justify-end">
                <span
                  class="text-base font-semibold border border-gray-300 rounded-lg px-4 py-1 bg-gray-100 text-sky-700"
                  v-if="myBusinessData.avg_rank !== 'N/A'"
                  >{{ myBusinessData.avg_rank }}</span
                >
                <!-- <span class="text-sm text-green-500">0.6 ↑</span> -->
              </div>
            </div>

            <!-- <div class="flex flex-col border border-gray-300 rounded-lg p-4">
              <h3 class="text-base font-semibold">All Keywords Avg.</h3>
              <div class="flex items-center gap-2">
                <span class="text-lg font-semibold">4.3</span>
                <span class="text-sm text-green-500">0.6 ↑</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex rounded-lg shadow-lg w-[100%] mx-auto my-8 bg-white border border-gray-300"
      v-if="
        formattedCompetitors && Object.keys(formattedCompetitors).length > 0
      "
    >
      <div class="px-4 my-4 flex flex-col w-full">
        <div
          class="flex max-sm:flex-col max-sm:gap-6 justify-between items-center max-sm:items-start"
        >
          <div
            class="flex gap-4 max-sm:flex-col max-sm:gap-6 max-sm:items-start"
          >
            <div class="">
              <v-menu
                v-model="menu"
                offset-y
                transition="scale-transition"
                :close-on-content-click="false"
                class="keyword-menu"
              >
                <template v-slot:activator="{ on }">
                  <button
                    v-on="on"
                    class="select-keyword text-white py-2 px-4 rounded max-sm:text-sm"
                    :class="{
                      'select-keyword': true,
                      'bg-green-500 cursor-pointer': !isLoadingCompetitors,
                      'bg-gray-400 cursor-not-allowed': isLoadingCompetitors, // Change color when loading
                      'cursor-not-allowed': isLoadingCompetitors, // Change cursor
                    }"
                    :disabled="isLoadingCompetitors || !keywords?.length"
                  >
                    Select Keyword
                    <v-progress-circular
                      v-if="isLoadingCompetitors"
                      indeterminate
                      color="white"
                      size="20"
                      width="2"
                      class="ml-2"
                    ></v-progress-circular>
                  </button>
                </template>

                <v-card class="min-w-[350px] max-w-[600px]">
                  <v-card-title>
                    <span class="text-h6">Select Keyword</span>
                  </v-card-title>
                  <v-card-text>
                    <!-- <v-text-field
                      v-model="search"
                      label="Keyword"
                      outlined
                      prepend-icon="mdi-magnify"
                      clearable
                    ></v-text-field> -->
                    <div class="header-row flex justify-between font-bold mb-2">
                      <span class="keyword-header flex-1 text-sm pl-2"
                        >Keyword</span
                      >
                      <span class="avg-rank-header text-sm pl-2"
                        >Avg. Rank</span
                      >
                      <!-- <span class="change-header text-sm pl-2">Change</span> -->
                    </div>
                    <div class="scrollable-list">
                      <v-list>
                        <v-list-item-group>
                          <v-list-item
                            v-for="(item, index) in keywordsWithRanks"
                            :key="index"
                            @click="handleKeywordChange(item.keyword)"
                          >
                            <v-list-item-content>
                              <div
                                class="flex justify-between items-center w-full"
                              >
                                <span class="keyword-name flex-1">{{
                                  item.keyword
                                }}</span>
                                <span
                                  class="avg-rank"
                                  :class="getRankClass(item.averageRank)"
                                  >{{ item.averageRank }}</span
                                >
                                <!-- <span
                                  class="change"
                                  :class="getChangeClass(keyword.change)"
                                  >{{ keyword.change > 0 ? "↑" : "↓" }}
                                  {{ Math.abs(keyword.change) }}</span
                                > -->
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>

            <div
              class="flex items-center"
              v-if="
                selectedKeyword &&
                formattedCompetitors &&
                Object.keys(formattedCompetitors).length > 0
              "
            >
              <label for="keyword-search" class="mr-2">Results for:</label>
              <span class="font-bold text-sky-700">{{ selectedKeyword }}</span>
            </div>
          </div>
          <!-- <div class="">
            <v-select
              v-model="selectedDate"
              :items="dateOptions"
              label="Select Timeline"
              dense
              outlined
              :loading="isLoadingDates"
              class="min-w-[200px] flex-1 sm:flex-none date-select"
              hide-details
              @change="selectDateRange"
            ></v-select>
          </div> -->
        </div>
        <!-- <div v-if="selectedKeyword"> -->
        <!-- Wrap everything in one scrollable container -->

        <!-- TIMELINE -->
        <!-- <div
          class="timeline"
          v-if="
            selectedKeyword &&
            formattedCompetitors &&
            Object.keys(formattedCompetitors).length > 0
          "
        >
          <div class="timeline-inner">
            <div class="timeline-line"></div>

            <div class="timeline-points">
              <div
                v-for="(point, index) in timelinePoints"
                :key="index"
                class="timeline-point"
                @mouseover="showTooltip(point)"
                @mouseleave="hideTooltip"
              >
                <div class="circle" :class="point.class"></div>
                <div class="date">{{ point.date }}</div>
              </div>
            </div>
          </div>

          <div
            v-if="tooltipVisible"
            class="tooltip"
            :style="{ left: tooltipPosition + 'px' }"
          >
            {{ tooltipData }}
          </div>
        </div> -->

        <!-- Competitors Table Section -->
        <div
          class="competitors-table mt-6"
          v-if="
            selectedKeyword &&
            formattedCompetitors &&
            Object.keys(formattedCompetitors).length > 0
          "
        >
          <div class="flex justify-between mt-3 mb-6">
            <h2 class="text-xl font-bold">
              Overall Top Ranking Competitors for
              <span class="text-sky-700">{{ selectedKeyword }}</span>
            </h2>
            <div>
              <v-menu
                v-model="gridMenu"
                offset-y
                transition="scale-transition"
                :close-on-content-click="false"
                class="grid-menu max-w-[250px] bg-white z-50"
              >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="primary">Select Grid</v-btn>
                </template>

                <v-container fluid>
                  <v-row>
                    <v-col cols="12">
                      <span
                        class="text-lg font-bold px-4 cursor-pointer text-blue-500"
                        @click="selectGrid('All Grids')"
                        >Select all grids</span
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-for="grid in gridOptions" :key="grid" cols="4">
                      <div
                        class="circle-marker-container flex justify-center items-center cursor-pointer"
                        :class="{ selected: selectedGrid === grid }"
                        @click="selectGrid(grid)"
                      >
                        <circle-marker color="green" :number="grid" />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-menu>
            </div>
          </div>
          <table class="min-w-full bg-white border border-gray-200">
            <thead>
              <tr class="bg-gray-100 text-left">
                <th class="py-2 px-4 border-b">Business Name</th>
                <th
                  class="py-2 px-4 border-b"
                  v-if="selectedGrid === 'All Grids'"
                >
                  Avg. Rank
                </th>
                <th class="py-2 px-4 border-b" v-else>Rank</th>
                <th class="py-2 px-4 border-b">Rating</th>
                <th class="py-2 px-4 border-b">Reviews</th>
                <!-- <th class="py-2 px-4 border-b">Primary Category</th> -->
                <th class="py-2 px-4 border-b">Categories</th>
              </tr>
            </thead>
            <tbody>
              <!-- Loop over each competitor in competitorRanksForKeyword -->
              <tr
                v-for="(compData, compName) in filteredCompetitors"
                :key="compName"
                class="hover:bg-gray-50 cursor-pointer"
                :class="{ 'font-bold text-blue-500': compData.isMyBusiness }"
                @click="openCompetitorDialog(compData, compName)"
              >
                <td class="py-2 px-4 border-b">
                  {{ compData.title ? compData.title : compName }}
                </td>
                <td
                  class="py-2 px-4 border-b"
                  :class="{ 'font-bold text-blue-500': compData.isMyBusiness }"
                >
                  {{
                    selectedGrid === "All Grids"
                      ? compData.avgRank
                      : compData.rank
                  }}
                </td>
                <td
                  class="py-2 px-4 border-b"
                  :class="{ 'font-bold text-blue-500': compData.isMyBusiness }"
                >
                  {{ compData.average_rating }}
                </td>
                <td
                  class="py-2 px-4 border-b"
                  :class="{ 'font-bold text-blue-500': compData.isMyBusiness }"
                >
                  {{ compData.total_reviews }}
                </td>
                <td
                  class="py-2 px-4 border-b"
                  :class="{ 'font-bold text-blue-500': compData.isMyBusiness }"
                >
                  {{ compData.category }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="w-full min-h-[500px]"
          v-if="
            selectedKeyword &&
            formattedCompetitors &&
            Object.keys(formattedCompetitors).length > 0
          "
        >
          <gmap-map
            v-if="selectedKeyword"
            :center="center"
            :zoom="13"
            map-type-id="terrain"
            style="width: 100%; height: 100%"
            class="map-container"
          >
            <!-- <gmap-marker :position="center" /> -->

            <!-- Grid Points -->
            <!-- working -->
            <gmap-marker
              v-for="(marker, index) in markers"
              :key="index"
              :position="marker.position"
              :icon="generateCustomIcon(marker.rank, 'green')"
              @click="showGridCompetitors(marker.gridId)"
            />

            <gmap-marker
              v-if="markers.length > 4"
              :position="markers[4].position"
              :icon="competitorIcon"
              @click="showGridCompetitors(markers[4].gridId)"
            />
          </gmap-map>
        </div>

        <v-dialog v-model="showCompetitorDialog" max-width="800px">
          <v-card>
            <v-card-title>
              <!-- Show competitor name and your business name in the header -->
              {{ selectedCompetitorName }} vs
              {{ myBusinessData.title || myBusiness }}
            </v-card-title>
            <v-card-text>
              <!-- Google Map with two sets of markers -->
              <gmap-map
                :center="mapCenter"
                :zoom="13"
                style="width: 100%; height: 400px"
              >
                <!-- Competitor markers -->
                <gmap-marker
                  v-for="(marker, idx) in competitorMarkers"
                  :key="`comp-${idx}`"
                  :position="marker.position"
                  :icon="generateCustomIcon(marker.rank, 'green')"
                />
              </gmap-map>

              <!-- Comparison Section -->
              <div class="comparison-section mt-4">
                <h3 class="font-semibold">Comparison</h3>
                <table class="min-w-full bg-white border border-gray-200">
                  <thead>
                    <tr class="bg-gray-100 text-left">
                      <th class="py-2 px-4 border-b">Business Name</th>
                      <th class="py-2 px-4 border-b">Avg. Rank</th>
                      <th class="py-2 px-4 border-b">Rating</th>
                      <th class="py-2 px-4 border-b">Reviews</th>
                      <!-- <th class="py-2 px-4 border-b">Primary Category</th> -->
                      <th class="py-2 px-4 border-b">Categories</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="selectedCompetitorData" class=" ">
                      <td class="py-2 px-4 border-b">
                        {{ selectedCompetitorData.title || myBusiness }}
                      </td>
                      <td class="py-2 px-4 border-b">
                        {{ selectedCompetitorData.avg_rank }}
                      </td>
                      <td class="py-2 px-4 border-b">
                        {{ selectedCompetitorData.average_rating }}
                      </td>
                      <td class="py-2 px-4 border-b">
                        {{ selectedCompetitorData.total_reviews }}
                      </td>
                      <td class="py-2 px-4 border-b">
                        {{ selectedCompetitorData.category }}
                      </td>
                    </tr>
                    <tr class="text-sky-500">
                      <td class="py-2 px-4 border-b">
                        {{ myBusinessData.title || myBusiness }}
                      </td>
                      <td class="py-2 px-4 border-b">
                        {{ myBusinessData.avg_rank }}
                      </td>
                      <td class="py-2 px-4 border-b">
                        {{ myBusinessData.average_rating }}
                      </td>
                      <td class="py-2 px-4 border-b">
                        {{ myBusinessData.total_reviews }}
                      </td>
                      <td class="py-2 px-4 border-b">
                        {{ myBusinessData.category }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text color="primary" @click="showCompetitorDialog = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Dialog showing ALL competitors for the clicked grid -->
        <v-dialog v-model="dialogVisible" max-width="700px">
          <!-- ADDED -->
          <v-card>
            <v-card-title>
              Competitors in Grid : {{ dialogCompetitors[0]?.grid_id }}
            </v-card-title>
            <v-card-text>
              <table class="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr class="bg-gray-100 text-left">
                    <th class="py-2 px-4 border-b">Business Name</th>
                    <th class="py-2 px-4 border-b">Rank</th>
                    <th class="py-2 px-4 border-b">Rating</th>
                    <th class="py-2 px-4 border-b">Total Reviews</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(comp, idx) in dialogCompetitors"
                    :key="idx"
                    class="hover:bg-gray-50"
                  >
                    <td
                      class="py-2 px-4 border-b"
                      :class="{ 'font-bold text-blue-500': comp.isMyBusiness }"
                    >
                      {{ comp.title }}
                    </td>
                    <td
                      class="py-2 px-4 border-b"
                      :class="{ 'font-bold text-blue-500': comp.isMyBusiness }"
                    >
                      {{ comp.rank }}
                    </td>
                    <td
                      class="py-2 px-4 border-b"
                      :class="{ 'font-bold text-blue-500': comp.isMyBusiness }"
                    >
                      {{ comp.average_rating }}
                    </td>
                    <td
                      class="py-2 px-4 border-b"
                      :class="{ 'font-bold text-blue-500': comp.isMyBusiness }"
                    >
                      {{ comp.total_reviews }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dialogVisible = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {
  collection,
  onSnapshot,
  query,
  where,
  getDoc,
  doc,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions, colUsers, auth, db } from "@/utils/firebase.utils";
import ToolTip from "@/components/ui/ToolTip.vue";
import Chart from "chart.js/auto";
// import { GmapMap, GmapMarker, GmapInfoWindow } from "vue2-google-maps";
import _ from "lodash";
import CircleMarker from "@/components/ui/common/circleMarker.vue"; // Adjust the path as necessary
import moment from "moment-timezone";

export default {
  components: { ToolTip, CircleMarker },
  props: {},
  data() {
    return {
      selectedGrid: null,
      gridOptions: Array.from({ length: 9 }, (_, i) => i + 1), // Grid options from 1 to 9

      filteredCompetitors: {}, // Store filtered competitors based on selected grid
      competitorsData: {}, // Store all competitors data

      gridMarkerIcon: "http://maps.google.com/mapfiles/ms/icons/green-dot.png", // Grid marker icon
      competitorIcon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
      myBusinessIcon: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
      // keywords: ["cake shop near me", "cake shop baner", "bakery near me"],
      keywords: [],
      formattedCompetitors: {},
      uniqueCompetitors: {},
      selectedKeyword: "",
      tabularData: null,
      dialogVisible: false,
      showCompetitorDialog: false,
      dialogCompetitors: [],
      competitorRanks: null,
      myBusinessMarkers: [],
      competitorMarkers: [],
      selectedCompetitorName: null,
      selectedCompetitor: null,
      mapCenter: null,
      myBusiness: "",
      myBusinessData: {},
      generatedDate: null,
      shape: {
        coords: [10, 10, 10, 15, 15, 15, 15, 10],
        type: "poly",
      },
      keywordsWithRanks: null,
      overallRanks: null,

      isDialogOpen: false,
      menu: false,
      gridMenu: false,
      search: "",
      marker: {
        lat: 50.60229509638775,
        lng: 3.0247059387528408,
      },

      dateOptions: [],
      selectedDate: null,
      isLoadingDates: false,
      timelinePoints: [
        { date: "12th Nov 2024", class: "green", data: "Data for 12th Nov" },
        { date: "19th Nov 2024", class: "green", data: "Data for 19th Nov" },
        { date: "26th Nov 2024", class: "green", data: "Data for 26th Nov" },
        { date: "3rd Dec 2024", class: "green", data: "Data for 3rd Dec" },
        { date: "10th Dec 2024", class: "green", data: "Data for 10th Dec" },
        { date: "17th Dec 2024", class: "green", data: "Data for 17th Dec" },
        { date: "24th Dec 2024", class: "orange", data: "Data for 24th Dec" },
        { date: "31st Dec 2024", class: "green", data: "Data for 31st Dec" },
        { date: "7th Jan 2025", class: "green", data: "Data for 7th Jan" },
        { date: "14th Jan 2025", class: "green", data: "Data for 14th Jan" },
      ],
      tooltipVisible: false,
      tooltipData: "",
      tooltipPosition: 0,
      center: { lat: 0, lng: 0 },
      markers: [],

      stores: [],
      showStoreDropdown: false,
      selectedWID: null,
      isLoading: false,
      sortBy: "location",
      sortDesc: false,
      selectedCompetitorId: null,
      processedCompetitors: [],
      selectedCompetitorRanks: null, // For dropdown
      isLoadingCompetitors: false,
      locationSearch: "",
    };
  },
  computed: {
    selectedCompetitorData() {
      // Find the competitor object based on the selectedCompetitorName
      const data =
        this.competitorMarkers.find(
          (comp) => comp.map_id === this.selectedCompetitorName
        ) || null;
      return data;
    },
    competitorRanksForKeyword() {
      if (!this.selectedKeyword || !this.selectedWID) return {};
      return this.competitorRanks[this.selectedKeyword] || {};
    },
    filteredStores() {
      if (!this.locationSearch) {
        return this.stores;
      }

      const searchTerm = this.locationSearch.toLowerCase();
      return this.stores.filter(
        (store) =>
          store.name?.toLowerCase().includes(searchTerm) ||
          store.sub_locality?.toLowerCase().includes(searchTerm) ||
          store.city?.toLowerCase().includes(searchTerm) ||
          store.state?.toLowerCase().includes(searchTerm)
      );
    },
  },
  watch: {
    selectedKeyword: {
      handler() {
        if (this.formattedCompetitors) {
          this.updateTableData();
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.generateDateOptions();
  },
  methods: {
    selectGrid(grid) {
      this.selectedGrid = grid; // Set the selected grid
      this.gridMenu = false;
      this.updateTableData();
    },
    generateCustomIcon(rank, color) {
      const svg = `
        <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
          <circle cx='20' cy='20' r='18' fill='${color}' stroke='white' stroke-width='2'/>
          <text x='20' y='20' font-size='14' font-weight='bold' text-anchor='middle' dominant-baseline='central' fill='white'>${rank}</text>
        </svg>
      `;
      return {
        url: "data:image/svg+xml;base64," + btoa(svg),
        scaledSize: { width: 40, height: 40 },
        anchor: { x: 20, y: 20 }, // Center alignment
      };
    },
    async fetchMyBusinessData() {
      const gbpRef = doc(
        db,
        "users",
        this.currentUID,
        "fly_workspaces",
        this.selectedWID,
        "connected-apps",
        "google_business_profile"
      );
      const gbpDoc = await getDoc(gbpRef);

      if (gbpDoc.exists()) {
        const gbpData = gbpDoc.data();
        const location = gbpData.accounts[0].locations[0];
        this.myBusiness = location.title;
        // this.myBusiness = 'The Cake Carnival Baner' || location.title;
      }
    },
    updateTableData() {
      if (this.selectedGrid === "All Grids") {
        // Show competitors for all grids for the selected keyword
        this.filteredCompetitors = this.competitorRanks[this.selectedKeyword];
      } else if (this.selectedGrid) {
        // Filter competitors based on selected grid
        this.filteredCompetitors =
          this.formattedCompetitors[this.selectedKeyword][this.selectedGrid] ||
          {};
      } else {
        // If no grid is selected, show aggregate data
        this.filteredCompetitors = this.competitorRanks[this.selectedKeyword];
      }
      this.loadMarkersForKeyword(this.selectedKeyword);
    },
    openCompetitorDialog(comp, title) {
      this.selectedCompetitor = comp;
      this.selectedCompetitor.title = title;
      this.selectedCompetitorName = comp.map_id;
      this.competitorMarkers = [];
      this.myBusinessMarkers = [];

      // Grab the arrays for each grid for the selected keyword
      const gridData = this.formattedCompetitors[this.selectedKeyword];
      if (!gridData) return;

      // We'll search for the competitor and your business in each grid,
      // and create a marker for each grid they appear in

      Object.keys(gridData).forEach((gridId) => {
        const compArray = gridData[gridId]; // array of competitor objects for this grid
        // Find the clicked competitor in this grid        const myBusinessRank =
        const businessRank =
          compArray.find((item) => item.map_id === comp.map_id)?.rank || 11; // Get the rank or null if not found
        const competitorObj = compArray.find((x) => x.map_id === comp.map_id);
        const firstComp = compArray[0];

        if (competitorObj) {
          const [lat, lng] = competitorObj.geo_location.split(",").map(Number);
          this.competitorMarkers.push({
            position: { lat, lng },
            gridId,
            rank: businessRank,
            title: title,
            map_id: comp.map_id,
            average_rating: comp.average_rating,
            total_reviews: comp.total_reviews,
            category: comp.category,
            avg_rank: comp.avgRank,
          });
        } else {
          const [lat, lng] = firstComp.geo_location.split(",").map(Number);
          this.competitorMarkers.push({
            position: { lat, lng },
            gridId,
            rank: businessRank,
            title: title,
            map_id: comp.map_id,
            average_rating: comp.average_rating,
            total_reviews: comp.total_reviews,
            category: comp.category,
            avg_rank: comp.avgRank,
          });
        }
        console.log("this.competitorMarkers:", this.competitorMarkers);
      });

      // Center the map on the competitor’s first marker if available,
      // else center on your business’s first marker, else fallback
      if (this.competitorMarkers.length) {
        this.mapCenter = this.competitorMarkers[0].position;
      } else if (this.myBusinessMarkers.length) {
        this.mapCenter = this.myBusinessMarkers[0].position;
      } else {
        this.mapCenter = { lat: 18.5728, lng: 73.7859 }; // fallback
      }

      // Finally show the dialog
      this.showCompetitorDialog = true;
    },
    async fetchData() {
      this.formattedCompetitors = {};

      if (!this.selectedWID) {
        this.$snackbar.show({
          message: "Please select a store before proceeding.",
          color: "error",
        });
        return;
      }
      await this.fetchCompetitorData();
      this.loadMarkersForKeyword(this.selectedKeyword);
      this.selectedGrid = "All Grids";
      this.updateTableData();
    },
    async handleKeywordChange(keyword) {
      this.selectedKeyword = keyword;
      this.menu = false;
    },

    async computeCompetitorRanks(competitorData) {
      let competitorRanks = {}; // per keyword
      // let overallRanks = {};    // aggregated across all keywords

      // 1) For each keyword
      Object.keys(competitorData).forEach((keyword) => {
        let keywordData = competitorData[keyword]; // e.g. { "1": [ ... ], "2": [ ... ], ... }
        let gridIds = Object.keys(keywordData); // the actual grid IDs
        let keywordCompetitors = {}; // collects data per competitor

        // 2) Build a map of (compName => { sum: 0, count: 0, gridRanks: {} })
        //    But we won't finalize the sum until we check missing grids
        //    So let's first gather a quick index by grid:
        let gridIndexById = {};

        gridIds.forEach((gridId) => {
          let compsInGrid = keywordData[gridId];
          // (Optional) If needed, sort compsInGrid by rank ascending
          // compsInGrid.sort((a, b) => a.rank - b.rank);

          // Build a dictionary for quick “find competitor by name”
          // so we know who is missing, and what the grid size is
          const gridMap = {};
          compsInGrid.forEach((comp) => {
            // console.log("comp:", comp);
            gridMap[comp.title] = comp;
          });
          // store: gridIndexById[gridId] = { map of compName => competitor }, plus size
          gridIndexById[gridId] = {
            compMap: gridMap,
            gridSize: compsInGrid.length,
          };
        });

        // 3) We want to gather a set of all competitor names (across all grids),
        //    so we can handle missing ones too.
        let allCompNames = new Set();
        gridIds.forEach((gridId) => {
          let compsInGrid = keywordData[gridId];
          compsInGrid.forEach((comp) => {
            allCompNames.add(comp.title);
          });
        });

        // 4) Initialize data for each competitor
        allCompNames.forEach((name) => {
          keywordCompetitors[name] = {
            gridRanks: {},
            totalRank: 0, // We'll accumulate real or "missing" rank here
          };
        });

        // 5) For each grid, if competitor is found => add actual rank
        //    else => add (gridSize + 1)
        gridIds.forEach((gridId) => {
          const { compMap, gridSize } = gridIndexById[gridId];

          // For each competitor name in allCompNames
          allCompNames.forEach((name) => {
            if (compMap[name]) {
              // console.log("compMap[name]:", compMap[name]);
              // competitor is present
              let actualRank = compMap[name].rank; // or index-based rank if you prefer
              keywordCompetitors[name].gridRanks[gridId] = actualRank;
              keywordCompetitors[name].totalRank += actualRank;
              keywordCompetitors[name].average_rating =
                compMap[name].average_rating;
              keywordCompetitors[name].total_reviews =
                compMap[name].total_reviews;
              keywordCompetitors[name].category = compMap[name].category;
              keywordCompetitors[name].title = compMap[name].title;
              keywordCompetitors[name].map_id = compMap[name].map_id;
              keywordCompetitors[name].isMyBusiness =
                compMap[name].isMyBusiness || false;
            } else {
              // competitor is missing => assign rank = gridSize + 1
              keywordCompetitors[name].gridRanks[gridId] = gridSize + 1;
              keywordCompetitors[name].totalRank += gridSize + 1;
              // keywordCompetitors[name].isMyBusiness = compMap[name].isMyBusiness || false;
            }
          });
        });

        // 6) Now each competitor has a totalRank across all grids. The # of grids is gridIds.length.
        Object.keys(keywordCompetitors).forEach((name) => {
          let data = keywordCompetitors[name];
          let totalGrids = gridIds.length; // e.g. 9
          let avg = data.totalRank / totalGrids;
          data.avgRank = avg.toFixed(1);
        });

        // **Sort competitors by avgRank in decreasing order**
        const sortedCompetitors = Object.entries(keywordCompetitors)
          .sort(([, a], [, b]) => a.avgRank - b.avgRank) // Sort in decreasing order
          .reduce((acc, [name, data]) => {
            acc[name] = data;
            return acc;
          }, {});

        competitorRanks[keyword] = sortedCompetitors;
      });

      // 7) Compute overall average ranks across all keywords (if needed)
      let overallRanks = {};
      Object.keys(competitorRanks).forEach((keyword) => {
        let keywordData = competitorRanks[keyword];

        Object.keys(keywordData).forEach((name) => {
          if (!overallRanks[name]) {
            overallRanks[name] = {
              keywordRanks: {},
              totalRank: 0,
              keywordCount: 0,
            };
          }

          let avgRank = parseFloat(keywordData[name].avgRank);
          overallRanks[name].keywordRanks[keyword] = avgRank;
          overallRanks[name].totalRank += avgRank;
          overallRanks[name].keywordCount += 1;
        });
      });

      // Finalize overall average
      Object.keys(overallRanks).forEach((name) => {
        let data = overallRanks[name];
        data.overallAvgRank = data.totalRank / data.keywordCount;
      });

      console.log("competitorRanks:", competitorRanks);
      console.log("overallRanks:", overallRanks);
      this.competitorRanks = competitorRanks;
      this.overallRanks = overallRanks;
      return { competitorRanks, overallRanks };
    },

    getRankClass(rank) {
      return rank < 4 ? "text-red-500" : "text-green-500";
    },
    getChangeClass(change) {
      return change > 0 ? "text-green-500" : "text-red-500";
    },
    showTooltip(point) {
      this.tooltipVisible = true;
      this.tooltipData = point.data;
      this.tooltipPosition = event.clientX; // Adjust position based on mouse event
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
    generateDateOptions() {
      this.dateOptions = [
        {
          text: "Last 1 Month",
          value: {
            months: 1,
          },
        },
        {
          text: "Last 3 Months",
          value: {
            months: 3,
          },
        },
        {
          text: "Last 6 Months",
          value: {
            months: 6,
          },
        },
        {
          text: "Last 1 Year",
          value: {
            months: 12,
          },
        },
      ];
      this.selectedDate = this.dateOptions[0].value;
    },
    async selectDateRange() {},

    handleClickOutside(event) {
      const profileDropdown = this.$refs.showStoreDropdown;
      if (profileDropdown && !profileDropdown.contains(event.target)) {
        this.closeProfileDropdown();
      }
    },
    closeProfileDropdown() {
      if (this.showStoreDropdown) {
        this.showStoreDropdown = false;
      }
    },
    toggleStoreDropdown(event) {
      this.showStoreDropdown = !this.showStoreDropdown;
      event.stopPropagation();
    },
    async selectStore(profile) {
      this.keywords = [];
      this.formattedCompetitors = {};
      this.selectedWID = profile.wid; // Set the selected WID
      this.fetchStorePosts(profile); // Fetch posts for the selected store
      this.showStoreDropdown = false; // Close the dropdown
    },
    async fetchStorePosts() {
      if (!this.selectedWID) {
        this.$snackbar.show({
          message: "Please select a store before proceeding.",
          color: "error",
        });
        return;
      } else {
        this.formattedCompetitors = {};
        this.competitorRanks = {};
        this.overallRanks = {};
        this.myBusinessMarkers = [];
        this.filteredCompetitors = null;
        await this.fetchMyBusinessData();
      }
    },
    async fetchKeywords() {
      const uid = this.currentUID;
      const keywordsRef = collection(
        db,
        "users",
        uid,
        "fly_workspaces",
        this.selectedWID,
        "keywords"
      );
      const keywordsSnapshot = await getDocs(
        query(keywordsRef, orderBy("created_at", "desc"), limit(1))
      );
      if (!keywordsSnapshot.empty) {
        const keywordsDoc = keywordsSnapshot.docs[0].data();
        if (
          keywordsDoc.keywords_volume_data &&
          keywordsDoc.keywords_volume_data.keywords
        ) {
          const keywords = keywordsDoc.keywords_volume_data.keywords;
          // this.keywords = keywords;
          this.keywordsWithRanks = keywords.map((keyword) => {
            console.log(
              "this.overallRanks[this.myBusiness:",
              this.myBusiness,
              this.overallRanks[this.myBusiness]
            );
            // const avgRank = this.overallRanks[this.myBusiness]?.keywordsWithRanks[keyword] || "N/A"; // Use "N/A" if no rank found
            const avgRank =
              this.overallRanks[this.myBusiness]?.overallAvgRank || "N/A"; // Use "N/A" if no rank found
            return {
              keyword,
              averageRank: avgRank.toFixed(1),
            };
          });
          this.selectedKeyword = keywords[0];
          this.$set(this, "selectedKeyword", keywords[0]);
        }
      }
    },
    async fetchStores() {
      const uid = this.currentUID;
      const storeRef = collection(db, "users", uid, "fly_workspaces");
      onSnapshot(storeRef, async (querySnapshot) => {
        this.stores = [];
        querySnapshot.forEach((doc) => {
          let storeData = doc.data();
          if (
            storeData.storeWorkspace &&
            storeData.admin === this.WID &&
            storeData.connected_apps?.includes("google_business_profile")
          ) {
            storeData.wid = doc.id;
            storeData.type = "store";
            storeData.display_name = `${storeData.sub_locality || ""} - ${
              storeData.name || ""
            }`;
            this.stores.push(storeData);
          }
        });
      });
    },
    loadMarkersForKeyword(keyword) {
      // If that keyword doesn't exist yet, bail out
      if (!this.formattedCompetitors[keyword]) {
        this.markers = [];
        return;
      }
      const gridData = this.formattedCompetitors[keyword];
      // We only want one marker per grid => 9 total, so we pick the first competitor
      const newMarkers = [];

      // For each of the 9 grids:
      Object.keys(gridData).forEach((gridId) => {
        const competitorList = gridData[gridId];
        if (!competitorList || competitorList.length === 0) return;

        // Use the FIRST competitor for lat/lng (all have same anyway)
        const firstComp = competitorList[0];
        const [lat, lng] = firstComp.geo_location.split(",").map(Number);

        const myBusinessRank =
          competitorList.find((comp) =>
            comp.title.toLowerCase().includes(this.myBusiness.toLowerCase())
          )?.rank || 11; // Get the rank or null if not found

        newMarkers.push({
          gridId,
          position: { lat, lng },
          rank: myBusinessRank,
        });
      });

      this.markers = newMarkers;

      // console.log("this.markers:", this.markers);
      // Center on grid #5 if found
      const middleMarker = this.markers.find((m) => m.gridId === "5");
      if (middleMarker) {
        this.center = middleMarker.position;
      } else if (this.markers.length) {
        this.center = this.markers[0].position;
      }
    },
    sortCompetitorsAsscending(competitors) {
      return competitors.sort((a, b) => {
        return (a.rank || 0) - (b.rank || 0); // Sort in descending order
      });
    },
    // Called when user clicks a marker => show its grid’s competitors in dialog
    showGridCompetitors(gridId) {
      // <-- ADDED or MODIFIED
      if (!this.selectedKeyword) return;
      const keywordData = this.formattedCompetitors[this.selectedKeyword] || {};
      const competitorList = keywordData[gridId] || [];
      this.dialogCompetitors = this.sortCompetitorsAsscending(competitorList);
      this.dialogVisible = true;
    },
    async fetchCompetitorData() {
      try {
        if (!this.selectedWID) {
          return;
        }
        this.isLoadingCompetitors = true;
        this.$loader.show();
        const uid = this.currentUID;
        const payload = {
          uid,
          wid: this.selectedWID,
          source: "competitors",
        };
        const competitorsByKeyword = {};
        // await this.fetchKeywords();
        const fetchCompetitorsData = httpsCallable(
          functions,
          "fetchCompetitorAnalysisReport",
          {
            timeout: 540000,
          }
        );
        const result = await fetchCompetitorsData(payload);
        console.log("result:", result.data);
        this.uniqueCompetitors = new Set(
          result.data.map((item) => item.map_id)
        );
        let geoLocations = new Map();

        if (result.data) {
          result.data.forEach((item) => {
            const {
              keyword,
              grid_id,
              average_rating,
              category,
              geo_location,
              location_id,
              map_id,
              rank,
              title,
              total_reviews,
              uid,
              wid,
              date,
              business_name,
              is_my_business,
            } = item;

            if (!competitorsByKeyword[keyword]) {
              competitorsByKeyword[keyword] = {};
            }
            if (!competitorsByKeyword[keyword][grid_id]) {
              competitorsByKeyword[keyword][grid_id] = [];
            }
            const firstKeyword = Object.keys(competitorsByKeyword)[0]; // Get the first keyword

            if (firstKeyword) {
              const gridData = competitorsByKeyword[firstKeyword];

              Object.keys(gridData).forEach((gridId) => {
                if (gridData[gridId].length > 0) {
                  this.generatedDate = moment(
                    gridData[gridId][0].date.value
                  ).format("LL");
                  geoLocations.set(gridId, gridData[gridId][0].geo_location);
                }
              });
            }
            let isMyBusiness = title
              .toLowerCase()
              .includes(business_name.toLowerCase());
            competitorsByKeyword[keyword][grid_id].push({
              business_name,
              title,
              average_rating,
              category,
              grid_id,
              geo_location,
              location_id,
              uid,
              wid,
              rank,
              total_reviews,
              map_id,
              date,
              isMyBusiness: is_my_business || isMyBusiness,
            });
            competitorsByKeyword[keyword][grid_id].sort(
              (a, b) => a.rank - b.rank
            );

            if (isMyBusiness || is_my_business) {
              this.myBusinessData.average_rating = average_rating || "N/A";
              this.myBusinessData.total_reviews = total_reviews || "N/A";
              this.myBusinessData.category = category || "N/A";
              this.myBusinessData.title = title || "N/A";
              console.log("this.myBusinessData:", this.myBusinessData);
            }
          });
        }
        // console.log("Formatted markers:", this.markers);
        this.formattedCompetitors = competitorsByKeyword;
        await this.computeCompetitorRanks(competitorsByKeyword);
        // await this.fetchKeywords();
        // Extract unique keywords for dropdown/filtering
        this.keywords = Object.keys(competitorsByKeyword);
        this.keywordsWithRanks = this.keywords.map((keyword) => {
          this.myBusinessData.avg_rank =
            this.overallRanks[this.myBusiness]?.overallAvgRank.toFixed(1) ||
            "N/A";
          console.log(
            "this.overallRanks[this.myBusiness:",
            this.myBusiness,
            this.overallRanks[this.myBusiness].keywordRanks[keyword]
          );
          const avgRank =
            this.overallRanks[this.myBusiness]?.keywordRanks[keyword]?.toFixed(
              1
            ) || 11; // Use "N/A" if no rank found
          // const avgRank = this.overallRanks[this.myBusiness]?.overallAvgRank || "N/A"; // Use "N/A" if no rank found
          return {
            keyword,
            averageRank: avgRank,
          };
        });

        console.log("Processed Competitor Data:", this.formattedCompetitors);
        console.log("geoLocations:", geoLocations);
      } catch (error) {
        console.error("Error fetching competitor data:", error);
        // this.competitors = []; // Set empty array on error
      } finally {
        this.isLoadingCompetitors = false;
        this.$loader.hide();
      }
    },
  },

  async mounted() {
    await this.fetchStores();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.text-xs {
  font-size: 0.75rem;
}
:deep(.v-text-field.location-input) {
  .v-input__slot {
    min-height: 40px !important;
  }

  .v-text-field__placeholder {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }

  input {
    font-size: 14px;
  }

  fieldset {
    border-color: rgba(0, 0, 0, 0.6) !important;
  }
}
.text-center {
  text-align: center;
}
img {
  object-fit: cover;
}
.cursor-pointer {
  cursor: pointer;
}
.hover\:bg-gray-100:hover {
  background-color: #f7fafc;
}
.leading-tight {
  line-height: 1.25;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
}
.skeleton-loader {
  display: inline-block;
  width: 50px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite;
}
.overflow-x-auto {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}
/* Optional: Custom scrollbar styling */
.overflow-x-auto::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.overflow-x-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tooltip-container {
  position: relative;
  z-index: 40;
}
.v-tooltip__content {
  z-index: 60 !important;
  position: fixed !important;
}
.v-icon {
  z-index: 40;
  position: relative;
}

.showStoreDropdown {
  background-color: white; /* Ensure solid background */
  z-index: 50;
  position: relative;
}

.date-select {
  position: relative;
  z-index: 40;
}

button.tabs {
  border-width: 2px;
  border-style: solid;
  border-color: #8056de;
}
button.tabs.active {
  background-color: #8056de;
  color: white;
}
.metrics-select {
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* This ensures the v-select dropdown menu appears above everything */
:deep(.v-menu__content) {
  z-index: 60 !important;
}

.report-container {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.overview {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
}

.hide-panel {
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  float: right;
}

.stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
}

.stat-item {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  flex: 1 1 45%;
  margin: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.value {
  display: flex;
  justify-content: center;
  align-items: center;
}

.up {
  color: green;
  margin-left: 5px;
}

.down {
  color: red;
}

.results {
  margin-top: 30px;
}

.select-keyword {
  /* background-color: #4caf50; */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.timeline {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.timeline-item {
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 50%;
  text-align: center;
  width: 80px;
}
.keyword-menu {
  z-index: 1000; /* Ensure the menu is above other elements */
}
.keyword-name {
  flex: 1; /* Allow the keyword name to take available space */
  text-align: left; /* Align text to the left */
}
.avg-rank,
.change {
  margin-left: 10px; /* Add some space between elements */
  min-width: 50px; /* Ensure a minimum width for alignment */
  text-align: right; /* Align text to the right */
}
.header-row {
  border-bottom: 1px solid #e0e0e0; /* Add a bottom border for separation */
  padding-bottom: 5px; /* Add some padding */
}

.scrollable-list {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
}

/* Timeline Styles */
.timeline {
  position: relative;
  width: 100%;
  overflow-x: auto; /* allow horizontal scrolling */
  margin-top: 20px;
}

/* The inner wrapper expands to fit all timeline-point items. */
.timeline-inner {
  position: relative;
  display: inline-block;
  /* inline-block ensures its width = total width of children, 
     so the line can match the full width of the points. */
}

/* Absolutely position the line inside timeline-inner so it stretches 
   behind all points, not just across the visible container. */
.timeline-line {
  position: absolute;
  top: 15px; /* adjust as needed */
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #ccc;
  z-index: 1; /* sit behind the points */
}

.timeline-points {
  display: flex; /* lay out points side by side */
  align-items: center;
  z-index: 5; /* appear above the line */
  padding: 10px 0; /* give a little vertical space */
}

/* Each point is fixed-width so they don’t shrink */
.timeline-point {
  flex: 0 0 auto;
  text-align: center;
  margin: 0 20px; /* spacing between points */
  position: relative;
  z-index: 2; /* appear above the line */
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #4caf50; /* default */
  margin: 0 auto; /* center horizontally */
}

.circle.orange {
  background-color: #ff9800;
}

.date {
  margin-top: 5px;
  white-space: nowrap; /* prevent date from wrapping */
}

.tooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  z-index: 999; /* above everything else */
  white-space: nowrap;
}
.map-container {
  width: 100%;
  height: 400px; /* Adjust height as per your requirement */
  max-width: 100%;
  overflow: hidden;
  border-radius: 8px; /* Optional for rounded corners */
}
.map-wrapper {
  width: 100%;
  overflow: hidden;
}
.grid-menu {
  background-color: white; /* Solid white background */
}
.v-menu__content {
  background-color: white !important; /* Set solid white background */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: add shadow for depth */
}
.selected .circle-marker {
  border: 2px solid blue; /* Border color for selected grid */
  border-radius: 50%; /* Optional: rounded corners */
  padding: 5px; /* Optional: padding for better visibility */
}
</style>
