<template>
  <div class="container">
    <!-- Title with Back Arrow -->
    <h1 class="reviews-title">
      <v-icon @click="goBack" class="back-arrow">mdi-arrow-left</v-icon>
      <!-- Back arrow -->
      Latest Review
    </h1>

    <!-- Loader -->
    <div v-if="isLoading" class="loader">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      <p>Loading...</p>
    </div>

    <!-- Toggle Buttons -->
    <div class="filter-buttons">
      <div class="filter-item">
        <v-switch
          v-model="isNoReviewActive"
          @change="toggleNoReview"
          label="Locations without Review"
        ></v-switch>
      </div>
      <div class="filter-item">
        <v-switch
          v-model="isUnreviewedLastWeekActive"
          @change="toggleUnreviewedLastWeek"
          label="Unreviewed Last Week"
        ></v-switch>
      </div>
    </div>

    <!-- Search Bar -->
    <div class="search-bar">
      <input
        type="text"
        v-model="searchTerm"
        placeholder="Search locations..."
        @input="filterLocations"
      />
    </div>

    <div
      v-if="filteredLocations.length === 0 && !isLoading"
      class="no-data-container"
    >
      <v-icon class="no-data-icon">mdi-alert-circle</v-icon>
      <p>No data available</p>
    </div>
    <div v-else>
      <table class="locations-table">
        <thead>
          <tr>
            <th>Location</th>
            <th>Last Received Review</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="location in filteredAndSearchedLocations.slice(
              (currentPage - 1) * itemsPerPage,
              currentPage * itemsPerPage
            )"
            :key="location.id"
          >
            <td>{{ location.storeLocation }}</td>
            <td>
              <span
                v-if="location.lastReviewUpdateTime === 'N/A'">
                N/A
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      small
                      v-bind="attrs"
                      v-on="on"
                      class="info-icon"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span>No review received yet</span>
                </v-tooltip>
                </span>
              <span v-else>{{ location.lastReviewUpdateTime }}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Pagination Controls -->
      <div class="pagination">
        <button
          class="pagination-button"
          @click="prevPage"
          :disabled="currentPage === 1"
        >
          Previous
        </button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button
          class="pagination-button"
          @click="nextPage"
          :disabled="currentPage === totalPages"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/utils/firebase.utils"; // Adjust the import based on your project structure
import { collection, onSnapshot } from "firebase/firestore";

export default {
  data() {
    return {
      locations: [], // Array to hold fetched locations
      filteredLocations: [], // Array to hold filtered locations
      noReview: [], // Array to hold locations without reviews
      noReviewLastWeek: [], // Array to hold locations without reviews for past week
      isNoReviewActive: false, // Toggle state for no review filter
      isUnreviewedLastWeekActive: false, // Toggle state for unreviewed last week filter
      isLoading: false, // Loading state
      currentPage: 1, // Current page for pagination
      itemsPerPage: 7, // Number of items per page
      searchTerm: "", // New data property for search term
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredLocations.length / this.itemsPerPage);
    },
    paginatedLocations() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredLocations.slice(start, end);
    },
    filteredAndSearchedLocations() {
      return this.filteredLocations.filter((location) =>
        location.storeLocation
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      );
    },
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    fetchLocations() {
      const uid = this.currentUID; // Get the current user ID
      const locationsRef = collection(db, "users", uid, "fly_workspaces");

      // Listen for real-time updates
      onSnapshot(locationsRef, (querySnapshot) => {
        this.locations = []; // Clear existing locations
        this.noReview = []; // Clear existing noReview array
        this.noReviewLastWeek = []; // Clear existing noReviewLastWeek array

        const oneWeekAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000); // Calculate one week ago

        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          let lastReviewUpdateTime = "N/A"; // Default value
          let noReviewLastWeek = false;
          // Check conditions
          if (
            docData.storeWorkspace &&
            docData.admin === this.WID &&
            docData.connected_apps?.includes("google_business_profile")
          ) {
            // Check lastReviewUpdateTime
            if (docData.lastReviewUpdateTime) {
              const reviewTime = docData.lastReviewUpdateTime.toDate(); // Convert Firestore timestamp to Date
              lastReviewUpdateTime = reviewTime.toLocaleDateString(); // Format date
              if (reviewTime <= oneWeekAgo) {
                noReviewLastWeek = true;
              }
            }

            // Add document ID and data to the locations array
            const location = {
              id: doc.id,
              storeLocation: docData.storeLocation,
              lastReviewUpdateTime,
            };
            this.locations.push(location);

            // Populate noReview array
            if (lastReviewUpdateTime === "N/A") {
              this.noReview.push(location);
            }

            if (noReviewLastWeek) {
              this.noReviewLastWeek.push(location);
            }
          }
        });
        this.filteredLocations = this.locations;
        this.currentPage = 1; // Reset to first page when data changes
      });
    },
    toggleNoReview() {
      if (this.isNoReviewActive) {
        this.isUnreviewedLastWeekActive = false; // Reset the other toggle
        this.filteredLocations = this.noReview; // Show locations without reviews
      } else {
        this.filteredLocations = this.locations; // Reset to all locations
      }
      this.searchTerm = "";
      this.currentPage = 1; // Reset to first page
    },
    toggleUnreviewedLastWeek() {
      if (this.isUnreviewedLastWeekActive) {
        this.isNoReviewActive = false; // Reset the other toggle
        this.filteredLocations = this.noReviewLastWeek; // Show unreviewed last week locations
      } else {
        this.filteredLocations = this.locations; // Reset to all locations
      }
      this.searchTerm = "";
      this.currentPage = 1; // Reset to first page
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goBack() {
      this.$router.push("/gmb-reviews"); // Navigate back to the specified route
    },
    filterLocations() {
      this.currentPage = 1; // Reset to first page when filtering
    },
  },
  mounted() {
    this.fetchLocations(); // Fetch locations on mount
  },
};
</script>

<style scoped>
.container {
  margin: 0 20px; /* Add left and right margin */
}

.reviews-title {
  font-size: 24px; /* Adjust font size */
  font-weight: bold; /* Make the title bold */
  color: #1a1a1a; /* Change color to match the design */
  margin-bottom: 20px; /* Add space below the title */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
}

.back-arrow {
  cursor: pointer; /* Change cursor to pointer */
  margin-right: 10px; /* Add space between arrow and title */
  background-color: #f0f0f0; /* Light background color */
  border-radius: 50%; /* Make it circular */
  padding: 5px; /* Add some padding */
  font-size: 18px; /* Adjust font size */
  line-height: 1; /* Center the arrow vertically */
}

.filter-buttons {
  display: flex; /* Use flexbox to align items in a row */
  margin-bottom: 20px; /* Add some space below the buttons */
}

.filter-item {
  display: flex; /* Align label and switch in a row */
  align-items: center; /* Center items vertically */
  margin-right: 20px; /* Add space between filter items */
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0; /* Add some margin for spacing */
}

.no-data-container {
  display: flex;
  flex-direction: column; /* Stack icon and text vertically */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  height: 60vh; /* Full height to center vertically */
  font-size: 24px; /* Adjust font size as needed */
  color: #555; /* Optional: Change text color */
}

.locations-table {
  width: calc(100% - 20px); /* Adjust width to account for right margin */
  border-collapse: collapse;
  margin-top: 20px;
  margin-right: 20px; /* Add right margin */
}

.locations-table th,
.locations-table td {
  border: 1px solid #e5e7eb;
  padding: 10px;
  text-align: left;
}

.locations-table th {
  background-color: #007bff; /* Blue shade for the header */
  color: white; /* White text color for contrast */
}

.active {
  background-color: #007bff; /* Highlight color for active filter */
  color: white; /* Change text color for contrast */
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.pagination-button {
  cursor: pointer; /* Change cursor to pointer */
  padding: 5px 10px; /* Add some padding */
  border: none; /* Remove default border */
  background-color: transparent; /* Make background transparent */
  color: #007bff; /* Change text color */
  font-weight: bold; /* Make text bold */
  margin-right: 20px; /* Add right margin to the Next button */
}

.pagination-button:disabled {
  color: #ccc; /* Change color for disabled state */
  cursor: not-allowed; /* Change cursor for disabled state */
}

/* Adjust alignment of the Next button */
.pagination span {
  margin: auto; /* Center the page indicator */
}

.search-bar {
  margin-bottom: 20px; /* Add space below the search bar */
  margin-right: 20px; /* Add right margin */
}

.search-bar input {
  width: 100%; /* Full width for the input */
  padding: 10px; /* Add some padding */
  border: 1px solid #e5e7eb; /* Border style */
  border-radius: 24px; /* Rounded corners */
}
</style>
