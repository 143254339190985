<template>
    <div class="p-4 md:p-6">
        <div class="max-w-7xl mx-auto">
            <!-- Header Section -->
            <h1 class="text-2xl font-semibold text-gray-800 mb-6">GMB Reviews Insights</h1>

            <!-- Controls Section -->
            <div class="bg-white rounded-lg shadow-sm p-4 md:p-6 mb-6">
                <div class="flex flex-col gap-4">
                    <!-- Location and Generate Section -->
                    <div class="flex flex-col md:flex-row md:items-center justify-between gap-4">
                        <div class="flex flex-col sm:flex-row items-stretch sm:items-center gap-4 w-full">
                            <!-- Location Dropdown -->
                            <div class="w-full sm:w-auto flex-1">
                                <div class="location-dropdown relative md:max-w-[200px]">
                                    <div class="relative">
                                        <input type="text" v-model="locationSearch"
                                            :placeholder="selectedStore ? '' : 'Select location'" @input="handleSearchInput"
                                            @click="toggleStoreDropdown($event)"
                                            class="w-full px-3 py-2 pr-8 border border-gray-300 rounded text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder-gray-600 font-medium" />
                                        <div v-if="selectedStore && !locationSearch" @click="toggleStoreDropdown($event)"
                                            class="absolute left-3 top-1/2 transform -translate-y-1/2 flex items-center gap-2 cursor-pointer w-[calc(100%-40px)]">
                                            <span class="text-sm font-medium truncate">{{ selectedStore.sub_locality }}</span>
                                        </div>
                                        <button @click="toggleStoreDropdown($event)"
                                            class="absolute inset-y-0 right-0 flex items-center px-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M19 9l-7 7-7-7" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div ref="showStoreDropdown" v-if="showStoreDropdown"
                                        class="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-50 max-h-60 w-full min-w-[250px] overflow-y-auto">
                                        <div class="p-2 cursor-pointer hover:bg-gray-50 border-b border-gray-200" 
                                            @click="clearStoreSelection">
                                            <div class="flex items-center gap-2">
                                                <input type="radio" :checked="!selectedWID" />
                                                <div class="flex flex-col flex-1">
                                                    <div class="flex items-center justify-between">
                                                        <span class="font-medium text-sm text-gray-600">All Locations</span>
                                                        <div v-if="totalAverageRating" class="flex ml-4 items-center gap-1 text-sm">
                                                            <span class="text-amber-500 font-medium ml-4">
                                                                {{totalAverageRating.toFixed(1) }}
                                                            </span>
                                                            <v-icon small color="amber">mdi-star</v-icon>
                                                            <span class="text-gray-500 text-xs">
                                                                ({{ reviewsCount  }})
                                                            </span>
                                                            <span v-if="totalAverageRating > 0" class="text-xs text-gray-500">
                                                                (Avg: {{ totalAverageRating }})
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-for="profile in filteredStores" :key="profile.wid"
                                            class="p-2 cursor-pointer hover:bg-gray-50" @click="selectStore(profile)">
                                            <div class="flex items-center gap-2">
                                                <input type="radio" :checked="selectedWID === profile.wid"
                                                    @change="selectStore(profile)" />
                                                <div class="flex flex-col flex-1">
                                                    <div class="flex items-center justify-between">
                                                        <span class="font-medium text-sm">{{ profile.sub_locality }}</span>
                                                        <div class="flex items-center gap-1 text-sm">
                                                            <span class="text-amber-500 font-medium">
                                                                {{ profile.averageRating.toFixed(1) }} <!-- Display average rating -->
                                                            </span>
                                                            <v-icon small color="amber">mdi-star</v-icon>
                                                        </div>
                                                    </div>
                                                    <div class="flex gap-1">
                                                        <span v-if="profile.city" class="text-xs text-gray-500">{{ profile.city }}</span>
                                                        <span v-if="profile.state" class="text-xs text-gray-500">{{ `, ${profile.state}` }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Load More Button -->
                                        <div class="p-2 text-center">
                                            <v-btn @click="loadMoreStores" class="w-full" :disabled="paginatedStores.length >= stores.length">
                                                Load More
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Rating Range Filter -->
                            <div class="w-full sm:w-auto flex-1">
                                <v-select
                                    v-model="selectedRatingRange"
                                    :items="ratingRanges"
                                    label="Filter by Rating"
                                    class="rating-filter"
                                    :disabled="isGenerating"
                                ></v-select>
                            </div>

                            <!-- Date Text -->
                            <p class="text-gray-600 font-medium text-base">Previous 6 months</p>

                            <!-- Generate Report Button -->
                            <v-btn color="primary" @click="generateReport" 
                                :loading="isGenerating"
                                class="px-6 h-[40px] w-full sm:w-auto">
                                <v-icon left>mdi-file-chart</v-icon>
                                Generate Report
                            </v-btn>
                        </div>
                    </div>

                    <!-- Action Buttons Section -->
                    <div v-if="hasData" class="flex flex-col sm:flex-row gap-4 pt-4 border-t border-gray-200">
                        <v-btn color="secondary" @click="downloadReport" :loading="isDownloading"
                            class="px-6 h-[40px] w-full sm:w-auto">
                            <v-icon left>mdi-download</v-icon>
                            Download Report
                        </v-btn>
                    </div>
                </div>
            </div>

            <!-- Report Content -->
            <div v-if="hasData" class="space-y-6">
                <!-- Monthly Reports Table -->
                <div class="bg-white rounded-lg shadow-sm overflow-hidden">
                    <div class="overflow-x-auto">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Month
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Total Reviews
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Average Rating
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Positive Feedback
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Negative Feedback
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <template v-for="(data, month) in reportData.data">
                                    <tr :key="month">
                                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {{ formatMonth(month) }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {{ data.totalReviews }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex items-center">
                                                <span class="text-sm font-medium text-gray-900 mr-2">
                                                    {{ calculateAverageRating(data.ratingDistribution).toFixed(1) }}
                                                </span>
                                                <div class="flex">
                                                    <v-icon v-for="i in 5" :key="i" small
                                                        :color="i <= calculateAverageRating(data.ratingDistribution) ? 'amber' : 'grey lighten-2'">
                                                        mdi-star
                                                    </v-icon>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4">
                                            <ul v-if="Array.isArray(data.feedback.positive) && data.feedback.positive.length > 0" 
                                                class="list-disc pl-4 text-sm text-green-600 space-y-1 max-w-xs">
                                                <li v-for="(point, index) in data.feedback.positive" :key="index">
                                                    {{ point }}
                                                </li>
                                            </ul>
                                            <span v-else class="text-sm text-gray-500">No positive feedback available</span>
                                        </td>
                                        <td class="px-6 py-4">
                                            <ul v-if="Array.isArray(data.feedback.negative) && data.feedback.negative.length > 0" 
                                                class="list-disc pl-4 text-sm text-red-600 space-y-1 max-w-xs">
                                                <li v-for="(point, index) in data.feedback.negative" :key="index">
                                                    {{ point }}
                                                </li>
                                            </ul>
                                            <span v-else class="text-sm text-gray-500">No negative feedback available</span>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Mobile View for Feedback -->
                <div class="md:hidden space-y-6">
                    <div v-for="(data, month) in reportData.data" :key="month" class="bg-white rounded-lg shadow-sm p-4">
                        <div class="mb-4">
                            <h3 class="text-lg font-semibold text-gray-800">{{ formatMonth(month) }}</h3>
                            <div class="flex items-center gap-4 mt-2">
                                <div class="text-sm text-gray-600">
                                    Total Reviews: {{ data.totalReviews }}
                                </div>
                                <div class="flex items-center ">
                                    <span class="text-sm font-medium  text-gray-900 mr-2">
                                        {{ calculateAverageRating(data.ratingDistribution).toFixed(1) }}
                                    </span>
                                    <div class="flex">
                                        <v-icon v-for="i in 5" :key="i" small
                                            :color="i <= calculateAverageRating(data.ratingDistribution) ? 'amber' : 'grey lighten-2'">
                                            mdi-star
                                        </v-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="space-y-4">
                            <div class="p-3 bg-green-50 rounded-lg">
                                <div class="font-medium text-green-700 mb-2">Positive Feedback</div>
                                <ul v-if="Array.isArray(data.feedback.positive) && data.feedback.positive.length > 0" 
                                    class="list-disc pl-4 text-sm text-green-600 space-y-1">
                                    <li v-for="(point, index) in data.feedback.positive" :key="index">
                                        {{ point }}
                                    </li>
                                </ul>
                                <span v-else class="text-sm text-gray-500">No positive feedback available</span>
                            </div>
                            <div class="p-3 bg-red-50 rounded-lg">
                                <div class="font-medium text-red-700 mb-2">Negative Feedback</div>
                                <ul v-if="Array.isArray(data.feedback.negative) && data.feedback.negative.length > 0" 
                                    class="list-disc pl-4 text-sm text-red-600 space-y-1">
                                    <li v-for="(point, index) in data.feedback.negative" :key="index">
                                        {{ point }}
                                    </li>
                                </ul>
                                <span v-else class="text-sm text-gray-500">No negative feedback available</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Loading State -->
            <div v-else-if="isGenerating" class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
                <v-skeleton-loader v-for="n in 4" :key="n" type="card" class="bg-white rounded-lg"></v-skeleton-loader>
            </div>

            <!-- No Data Available State -->
            <div v-else class="text-center py-12">
                <v-icon size="64" color="grey lighten-1">mdi-alert-circle-outline</v-icon>
                <p class="mt-4 text-gray-600">No data available</p>
            </div>
        </div>
    </div>
</template>

<script>
import { collection, onSnapshot, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";

export default {
    name: 'GmbReviewsReport',

    data() {
        return {
            selectedDate: null,
            dateOptions: [],
            isLoadingDates: false,
            isGenerating: false,
            reportData: null,
            stores: [],
            showStoreDropdown: false,
            selectedWID: null,
            reviewsCount:null,
            selectedStore: null,
            locationSearch: "",
            isDownloading: false,
            isSending: false,
            locationRatings: null,
            isLoadingRatings: false,
            selectedRatingRange: null,
            ratingRanges: [
                { text: 'All Ratings', value: null },
                { text: 'Above 4 Stars', value: '4' },
                { text: '3-4 Stars', value: '3' },
                { text: '2-3 Stars', value: '2' },
                { text: 'Below 2 Stars', value: '0' },
            ],
            storesToShow: 20,
            totalAverageRating: 0,
        }
    },

    computed: {
        filteredStores() {
            const searchTerm = this.locationSearch.toLowerCase();
            return this.stores.filter(store => {
                const matchesSearch = store.name?.toLowerCase().includes(searchTerm) ||
                                      store.sub_locality?.toLowerCase().includes(searchTerm) ||
                                      store.city?.toLowerCase().includes(searchTerm);

                const matchesRating = this.selectedRatingRange ? 
                    (this.selectedRatingRange === '0' ? store.averageRating < 2 : 
                    store.averageRating >= Number(this.selectedRatingRange) && 
                    store.averageRating < Number(this.selectedRatingRange) + 1) : true;

                return matchesSearch && matchesRating;
            });
        },

        paginatedStores() {
            return this.filteredStores.slice(0, this.storesToShow);
        },

        hasData() {
            return this.reportData?.data && Object.keys(this.reportData.data).length > 0;
        },

        currentMonthData() {
            if (!this.reportData?.data) return null;
            // Get the first month's data if no specific month is selected
            const monthKey = Object.keys(this.reportData.data)[0];
            return this.reportData.data[monthKey];
        },

        isLocationDropdownDisabled() {
            return this.selectedRatingRange !== null;
        },

        canGenerateReport() {
            return !this.isLoadingRatings && (!this.selectedRatingRange || this.locationRatings);
        },
    },

    methods: {
        async generateReport() {
            this.isGenerating = true;
            try {
                const generateReviewsReport = httpsCallable(functions, 'gmbReviewsReport');
                let payload = {
                    action: 'generate_report',
                    uid: this.currentUID,
                };

                const locations = [];
                
                // Filter stores based on rating range if selected
                let storesForReport = [];
                if (this.selectedRatingRange) {
                    // Convert rating range to numbers for comparison
                    const minRating = Number(this.selectedRatingRange);
                    const maxRating = minRating + 1;
                    
                    storesForReport = this.stores.filter(store => {
                        const storeRating = store.averageRating;
                        if (!storeRating) return false;
                        
                        // Special case for "Below 2 Stars"
                        if (minRating === 0) {
                            return storeRating < 2;
                        }
                        
                        return storeRating >= minRating && storeRating < maxRating;
                    });
                } else {
                    // If no rating range selected, use selected store or all stores
                    storesForReport = this.selectedStore ? [this.selectedStore] : this.stores;
                }

                // Add filtered locations to payload
                storesForReport.forEach((store) => {
                    locations.push({
                        locationId: store.locationId,
                        accountId: store.gmbAccountId,
                    });
                });

                if (locations.length === 0) {
                    throw new Error('No locations match the selected criteria');
                }

                // Ensure the payload format matches the previous code
                payload = {
                    ...payload,
                    locations,
                    ratingRange: this.selectedRatingRange // Ensure this is included
                };
                const result = await generateReviewsReport(payload);

                if (result.data?.success) {
                    console.log(result.data,"this is result data");
                    this.reportData = result.data;
                } else {
                    throw new Error(result.data?.error || 'Failed to generate report');
                }
            } catch (error) {
                console.error('Error generating report:', error);
                this.$alert.show(error.message || 'Failed to generate report');
            } finally {
                this.isGenerating = false;
            }
        },

        async fetchStores() {
            try {
                if (!this.currentUID || !this.WID) {
                    console.log('Waiting for user data...');
                    return;
                }

                // Create a batch query for stores without real-time listener
                const storeRef = collection(db, "users", this.currentUID, "fly_workspaces");
                const storeQuery = query(storeRef, where("admin", "==", this.WID), where("storeWorkspace", "==", true));

                // Fetch stores once instead of setting up a real-time listener
                const querySnapshot = await getDocs(storeQuery);
                const storesDocs = querySnapshot.docs;

                const storesArray = storesDocs.map(storeDoc => {
                    const storeData = storeDoc.data(); 
                    return {
                        ...storeData,
                        wid: storeDoc.id,
                        type: "store",
                        averageRating: storeData.averageRating || 0, // Ensure averageRating is fetched correctly
                        locationId:storeData.gmbLocationId,
                        reviewCount: storeData.reviewCount || 0 // Fetch reviewCount
                    };
                });

                this.stores = storesArray; // Store all fetched stores
                this.storesToShow = this.stores.slice(0, this.storesToShow); // Initialize visible stores
                // Calculate total average rating correctly
                const totalRatings = this.stores.reduce((sum, store) => {
                    const storeRating = store.averageRating || 0; // Ensure averageRating is fetched correctly
                    const storeReviewCount = store.reviewCount || 0; // Use reviewCount or default to 0
                    return sum + (storeRating * storeReviewCount); // Calculate weighted rating
                }, 0);

                const totalCount = this.stores.reduce((sum, store) => sum + (store.reviewCount || 0), 0); // Sum of all review counts
                this.reviewsCount = totalCount;
                this.totalAverageRating = totalCount > 0 ? (totalRatings / totalCount).toFixed(1) : 0;

            } catch (error) {
                console.error('Error fetching stores:', error);
                this.$alert?.show('Failed to fetch stores: ' + error.message);
            }
        },

        calculateAverageRating(ratingDistribution) {
            if (!ratingDistribution) return 0;
            
            const totalRatings = Object.entries(ratingDistribution).reduce(
                (sum, [rating, count]) => sum + (Number(rating) * count), 0
            );
            const totalCount = Object.values(ratingDistribution).reduce((sum, count) => sum + count, 0);
            
            return totalCount > 0 ? totalRatings / totalCount : 0;
        },

        calculateResponseRate(monthData) {
            if (!monthData?.totalReviews) return 0;
            return ((monthData.repliedCount / monthData.totalReviews) * 100).toFixed(0);
        },

        getSentimentColor(type) {
            const colors = {
                positive: 'bg-green-50 text-green-700',
                negative: 'bg-red-50 text-red-700',
                neutral: 'bg-blue-50 text-blue-700'
            };
            return colors[type] || 'bg-gray-50 text-gray-700';
        },

        toggleStoreDropdown(event) {
            if (this.isLocationDropdownDisabled) {
                return;
            }
            event.stopPropagation();
            this.showStoreDropdown = !this.showStoreDropdown;
            if (this.showStoreDropdown) {
                this.locationSearch = '';
            }
        },

        handleClickOutside(event) {
            const dropdown = this.$refs.showStoreDropdown;
            const input = event.target.closest(".location-dropdown");
            if (!input && dropdown && !dropdown.contains(event.target)) {
                this.showStoreDropdown = false;
            }
        },

        selectStore(profile) {
            // console.log('Selecting store:', profile);
            this.selectedStore = profile;
            this.selectedWID = profile.wid;
            this.locationSearch = '';
            this.showStoreDropdown = false;

            // Generate report automatically when store is selected
        },

        handleSearchInput(event) {
            // If there's a selected store, clear it when user starts searching
            if (this.selectedStore && event.target.value) {
                this.selectedStore = null;
                this.selectedWID = null;
            }
            this.showStoreDropdown = true;

            // Trigger filtering when the rating range is selected
            this.selectedRatingRange && this.fetchStores(); // Ensure stores are fetched again based on the selected rating
        },

        generateDateOptions() {
            const options = [];
            const today = new Date();
            const currentMonth = today.getMonth();
            const currentYear = today.getFullYear();

            // Generate options for the past 12 months
            for (let i = 0; i < 12; i++) {
                const date = new Date(currentYear, currentMonth - i, 1);
                const year = date.getFullYear();
                const monthName = date.toLocaleString('default', { month: 'long' });
                
                options.push({
                    text: `${monthName} ${year}`,
                    value: {
                        year: year,
                        month: date.getMonth() + 1
                    }
                });
            }

            this.dateOptions = options;
            this.selectedDate = null;
        },

        formatMonth(monthStr) {
            const [year, month] = monthStr.split('-');
            return new Date(year, month - 1).toLocaleString('default', { 
                month: 'long', 
                year: 'numeric' 
            });
        },

        truncateText(text, maxLength) {
            if (!text) return '';
            return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
        },

        async downloadReport() {
            this.isDownloading = true;
            try {
                const html2pdf = (await import('html2pdf.js')).default;
                
                // Create a temporary container for the PDF content
                const container = document.createElement('div');
                container.style.padding = '24px';
                container.style.fontFamily = 'Arial, sans-serif';
                container.style.maxWidth = '1200px';
                container.style.margin = '0 auto';

                // Add report header
                const title = this.selectedStore 
                    ? `GMB Reviews Insights - ${this.selectedStore.sub_locality}`
                    : 'GMB Reviews Insights - All Locations';

                container.innerHTML = `
                    <div style="margin-bottom: 24px;">
                        <h1 style="font-size: 24px; font-weight: 600; color: #1F2937; margin-bottom: 24px;">${title}</h1>
                    </div>
                `;

                // Add monthly data table with improved formatting
                if (this.reportData?.data) {
                    const tableContainer = document.createElement('div');
                    tableContainer.style.backgroundColor = '#FFFFFF';
                    tableContainer.style.borderRadius = '8px';
                    tableContainer.style.boxShadow = '0 1px 2px 0 rgba(0, 0, 0, 0.05)';
                    tableContainer.style.overflow = 'hidden';
                    tableContainer.style.pageBreakInside = 'avoid'; // Prevent table from breaking across pages

                    tableContainer.innerHTML = `
                        <table style="width: 100%; border-collapse: collapse;">
                            <thead style="background-color: #F9FAFB; display: table-header-group;">
                                <tr>
                                    <th style="padding: 12px 24px; text-align: left; font-size: 12px; font-weight: 500; color: #6B7280; text-transform: uppercase; border-bottom: 1px solid #E5E7EB;">Month</th>
                                    <th style="padding: 12px 24px; text-align: left; font-size: 12px; font-weight: 500; color: #6B7280; text-transform: uppercase; border-bottom: 1px solid #E5E7EB;">Total Reviews</th>
                                    <th style="padding: 12px 24px; text-align: left; font-size: 12px; font-weight: 500; color: #6B7280; text-transform: uppercase; border-bottom: 1px solid #E5E7EB;">Average Rating</th>
                                    <th style="padding: 12px 24px; text-align: left; font-size: 12px; font-weight: 500; color: #6B7280; text-transform: uppercase; border-bottom: 1px solid #E5E7EB;">Positive Feedback</th>
                                    <th style="padding: 12px 24px; text-align: left; font-size: 12px; font-weight: 500; color: #6B7280; text-transform: uppercase; border-bottom: 1px solid #E5E7EB;">Negative Feedback</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${Object.entries(this.reportData.data).map(([month, data]) => `
                                    <tr style="page-break-inside: avoid;">
                                        <td style="padding: 16px 24px; font-size: 14px; font-weight: 500; color: #111827; vertical-align: top;">
                                            ${this.formatMonth(month)}
                                        </td>
                                        <td style="padding: 16px 24px; font-size: 14px; color: #6B7280; vertical-align: top;">
                                            ${data.totalReviews}
                                        </td>
                                        <td style="padding: 16px 24px; vertical-align: top;">
                                            <div style="display: flex; align-items: center;">
                                                <span style="font-size: 14px; font-weight: 500; color: #111827; margin-right: 8px;">
                                                    ${this.calculateAverageRating(data.ratingDistribution).toFixed(1)}
                                                </span>
                                                <div style="color: #F59E0B;">★★★★★</div>
                                            </div>
                                        </td>
                                        <td style="padding: 16px 24px; vertical-align: top;">
                                            ${this.formatFeedbackForPDF(data.feedback.positive, true)}
                                        </td>
                                        <td style="padding: 16px 24px; vertical-align: top;">
                                            ${this.formatFeedbackForPDF(data.feedback.negative, false)}
                                        </td>
                                    </tr>
                                    ${data.keywords?.length > 0 ? `
                                        <tr style="background-color: #F9FAFB; page-break-inside: avoid;">
                                            <td colspan="5" style="padding: 12px 24px;">
                                                <div style="display: flex; flex-wrap: wrap; gap: 8px; align-items: center;">
                                                    <span style="font-size: 12px; font-weight: 500; color: #6B7280; margin-right: 8px;">Review Highlights:</span>
                                                    ${data.keywords.map(keyword => `
                                                        <span style="
                                                            display: inline-block;
                                                            padding: 4px 8px 1.5% 8px;
                                                            border-radius: 9999px;
                                                            font-size: 12px;
                                                            font-weight: 500;
                                                            margin: 2px;
                                                            ${keyword.type === 'positive' 
                                                                ? 'background-color: #DEF7EC; color: #03543F;'
                                                                : keyword.type === 'negative'
                                                                ? 'background-color: #FDE8E8; color: #9B1C1C;'
                                                                : 'background-color: #E1EFFE; color: #1E429F;'
                                                            }
                                                        ">
                                                            ${keyword.text}
                                                        </span>
                                                    `).join('')}
                                                </div>
                                            </td>
                                        </tr>
                                    ` : ''}
                                `).join('')}
                            </tbody>
                        </table>
                    `;

                    container.appendChild(tableContainer);
                }

                // Configure PDF options with improved settings
                const opt = {
                    margin: [15, 15],
                    filename: `gmb-reviews-report-${new Date().toISOString().split('T')[0]}.pdf`,
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { 
                        scale: 2,
                        logging: false,
                        useCORS: true
                    },
                    jsPDF: { 
                        unit: 'mm', 
                        format: 'a4', 
                        orientation: 'landscape',
                        compress: true
                    },
                    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
                };

                // Generate and download PDF
                await html2pdf().set(opt).from(container).save();

            } catch (error) {
                console.error('Error downloading report:', error);
                this.$alert.show('Failed to download report');
            } finally {
                this.isDownloading = false;
            }
        },

        formatFeedbackForPDF(feedback, isPositive) {
            if (!Array.isArray(feedback) || feedback.length === 0) {
                return '<span style="color: #6B7280; font-size: 14px;">No feedback available</span>';
            }

            return `
                <ul style="margin: 0; padding-left: 16px; list-style-type: disc;">
                    ${feedback.map(point => `
                        <li style="color: ${isPositive ? '#059669' : '#DC2626'}; font-size: 14px; margin-bottom: 4px;">
                            ${point}
                        </li>
                    `).join('')}
                </ul>
            `;
        },

        async sendReport() {
            this.isSending = true;
            try {
                const html2pdf = (await import('html2pdf.js')).default;
                
                // Create a temporary container for the PDF content
                const container = document.createElement('div');
                container.style.padding = '20px';
                container.style.fontFamily = 'Arial, sans-serif';

                // Add report title and date
                const title = this.selectedStore 
                    ? `GMB Reviews Report - ${this.selectedStore.sub_locality}`
                    : 'GMB Reviews Report - All Locations';

                container.innerHTML = `
                    <h1 style="color: #1a202c; margin-bottom: 20px; font-size: 24px;">${title}</h1>
                    <p style="color: #4a5568; margin-bottom: 30px;">Report generated on ${new Date().toLocaleDateString()}</p>
                `;

                // Add monthly data
                if (this.reportData?.data) {
                    Object.entries(this.reportData.data).forEach(([month, data]) => {
                        const monthSection = document.createElement('div');
                        monthSection.style.marginBottom = '40px';
                        monthSection.innerHTML = `
                            <h2 style="color: #2d3748; margin-bottom: 20px; padding-bottom: 10px; border-bottom: 2px solid #e2e8f0; font-size: 20px;">
                                ${this.formatMonth(month)}
                            </h2>
                            <div style="margin-bottom: 20px;">
                                <p style="margin-bottom: 10px;">
                                    <strong>Total Reviews:</strong> ${data.totalReviews}
                                </p>
                                <p style="margin-bottom: 20px;">
                                    <strong>Average Rating:</strong> ${this.calculateAverageRating(data.ratingDistribution).toFixed(1)} ⭐
                                </p>
                            </div>
                            <div style="margin-bottom: 20px;">
                                <h3 style="color: #2f855a; margin-bottom: 10px; font-size: 16px;">Positive Feedback</h3>
                                ${this.formatFeedbackForPDF(data.feedback.positive, 'green')}
                            </div>
                            <div style="margin-bottom: 20px;">
                                <h3 style="color: #c53030; margin-bottom: 10px; font-size: 16px;">Negative Feedback</h3>
                                ${this.formatFeedbackForPDF(data.feedback.negative, 'red')}
                            </div>
                        `;
                        container.appendChild(monthSection);
                    });
                }

                // Configure PDF options
                const opt = {
                    margin: [15, 15],
                    filename: `gmb-reviews-report-${new Date().toISOString().split('T')[0]}.pdf`,
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { 
                        scale: 2,
                        logging: false,
                        useCORS: true
                    },
                    jsPDF: { 
                        unit: 'mm', 
                        format: 'a4', 
                        orientation: 'portrait'
                    }
                };

                // Generate PDF blob
                const pdfBlob = await html2pdf().set(opt).from(container).output('blob');
                const pdfUrl = URL.createObjectURL(pdfBlob);

                // Create sharing options dialog
                const shareOptions = document.createElement('div');
                shareOptions.innerHTML = `
                    <div class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div class="bg-white rounded-lg p-6 max-w-md w-full mx-4">
                            <h3 class="text-lg font-medium mb-4">Share Report</h3>
                            <div class="space-y-4">
                                <button class="w-full py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center justify-center gap-2">
                                    <i class="mdi mdi-email"></i>
                                    Send via Email
                                </button>
                                <button class="w-full py-2 px-4 bg-green-500 text-white rounded hover:bg-green-600 flex items-center justify-center gap-2">
                                    <i class="mdi mdi-whatsapp"></i>
                                    Share via WhatsApp
                                </button>
                                <button class="w-full py-2 px-4 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 flex items-center justify-center gap-2">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                `;

                document.body.appendChild(shareOptions);

                // Handle sharing options
                const buttons = shareOptions.getElementsByTagName('button');
                buttons[0].onclick = () => {
                    // Email sharing
                    const emailSubject = encodeURIComponent(title);
                    const emailBody = encodeURIComponent(`Please find attached the GMB Reviews Insights generated on ${new Date().toLocaleDateString()}.`);
                    const mailtoLink = `mailto:?subject=${emailSubject}&body=${emailBody}&attachment=${pdfUrl}`;
                    window.open(mailtoLink);
                    document.body.removeChild(shareOptions);
                };

                buttons[1].onclick = () => {
                    // WhatsApp sharing
                    const whatsappText = encodeURIComponent(`${title}\n\nGenerated on ${new Date().toLocaleDateString()}`);
                    const whatsappLink = `https://wa.me/?text=${whatsappText}&attachment=${pdfUrl}`;
                    window.open(whatsappLink);
                    document.body.removeChild(shareOptions);
                };

                buttons[2].onclick = () => {
                    document.body.removeChild(shareOptions);
                };

                // Cleanup
                setTimeout(() => {
                    URL.revokeObjectURL(pdfUrl);
                }, 60000); // Cleanup after 1 minute

            } catch (error) {
                console.error('Error sharing report:', error);
                this.$alert.show('Failed to share report');
            } finally {
                this.isSending = false;
            }
        },

        // async fetchLocationRatings() {
        //     this.isLoadingRatings = true;
        //     try {
        //         const computeAverageRating = httpsCallable(functions, 'computeAverageRating');
        //         const locationIds = [];

        //         // Construct locationIds array similar to generateReport
        //         for (const store of this.stores) {
        //             locationIds.push(store.locationId)
        //         }

        //         if (locationIds.length === 0) {
        //             throw new Error('No location IDs available');
        //         }

        //         const result = await computeAverageRating({ locationIds });
                
        //         if (result.data?.success) {
        //             console.log('Location Ratings:', result.data.data);
        //             this.locationRatings = result.data.data;
        //         } else {
        //             throw new Error(result.data?.error || 'Failed to fetch ratings');
        //         }
        //     } catch (error) {
        //         console.error('Error fetching location ratings:', error);
        //         this.$alert?.show('Failed to fetch location ratings');
        //     } finally {
        //         this.isLoadingRatings = false;
        //     }
        // },

        clearStoreSelection() {
            this.selectedStore = null;
            this.selectedWID = null;
            this.locationSearch = '';
            this.showStoreDropdown = false;
            this.selectedRatingRange = null; // Reset rating filter
        },

        getKeywordClass(sentiment) {
            switch (sentiment?.toLowerCase()) {
                case 'positive':
                    return 'bg-green-100 text-green-800';
                case 'negative':
                    return 'bg-red-100 text-red-800';
                case 'neutral':
                    return 'bg-blue-100 text-blue-800';
                default:
                    return 'bg-gray-100 text-gray-800';
            }
        },

        loadMoreStores() {
            this.storesToShow += 20; // Increase the number of stores to show by 20
        },

    },

    async created() {
        this.generateDateOptions();
        if (this.currentUID && this.WID) {
            await this.fetchStores();
        }
    },

    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    },

    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
    },

}
</script>

<style scoped>
.v-select {
    max-width: none !important;
}

@media (min-width: 640px) {
    .v-select {
        max-width: 300px !important;
    }
}

/* Ensure consistent height for select inputs and button */
:deep(.v-input__control) {
    min-height: 40px !important;
}

:deep(.v-input__slot) {
    min-height: 40px !important;
}

/* Remove bottom margin from selects */
:deep(.v-text-field__details) {
    display: none;
}

/* Location dropdown styles */
.location-dropdown {
    position: relative;
}

.location-dropdown input {
    background: transparent;
}

.location-dropdown .relative:hover {
    border-color: #a0aec0;
}

.location-dropdown .relative:focus-within {
    border-color: #3b82f6;
    box-shadow: 0 0 0 1px rgba(59, 130, 246, 0.5);
}

/* Add this to ensure proper table scroll on mobile */
.overflow-x-auto {
    -webkit-overflow-scrolling: touch;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.overflow-x-auto::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.overflow-x-auto {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

@media (max-width: 768px) {
    .table-container {
        display: none;
    }
}

/* Button consistency */
:deep(.v-btn) {
    text-transform: none !important;
}

/* Ensure buttons stack properly on mobile */
@media (max-width: 640px) {
    .v-btn {
        width: 100%;
    }
}

.location-dropdown .v-progress-circular {
    margin: 0 4px;
}

.rating-filter {
    min-width: 150px;
}

/* Ensure consistent height with other filters */
:deep(.rating-filter .v-input__control) {
    min-height: 40px !important;
}

:deep(.rating-filter .v-input__slot) {
    min-height: 40px !important;
}

/* Add these new styles */
.rating-filter {
    max-width: 200px !important;
}

:deep(.rating-filter .v-input__slot) {
    cursor: pointer !important;
}

:deep(.rating-filter.v-input--is-disabled .v-input__slot) {
    cursor: not-allowed !important;
    opacity: 0.7;
}
</style>
