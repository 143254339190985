<template>
  <div class="p-4 md:p-6">
    <div class="max-w-7xl mx-auto">
      <!-- Header Section -->
      <h1 class="text-2xl font-semibold text-gray-800 mb-6">GMB Search Insights</h1>

      <!-- Controls Section -->
      <div class="bg-white rounded-lg shadow-sm p-4 md:p-6 mb-6">
        <div class="flex flex-col gap-4">
          <div class="flex flex-col md:flex-row md:items-center justify-between gap-4">
            <div class="flex flex-col sm:flex-row items-stretch sm:items-center gap-4 w-full">
              <!-- Location Dropdown -->
              <div class="relative w-full sm:w-auto flex-1">
                <input
                  type="text"
                  v-model="locationSearch"
                  :placeholder="selectedStore ? '' : 'Select location'"
                  @input="handleSearchInput"
                  @click="toggleStoreDropdown"
                  class="w-full px-3 py-2 pr-8 border border-gray-300 rounded text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder-gray-600 font-medium"
                />
                <div v-if="selectedStore && !locationSearch"
                  @click="toggleStoreDropdown"
                  class="absolute left-3 top-1/2 transform -translate-y-1/2 flex items-center gap-2 cursor-pointer w-[calc(100%-40px)]">
                  <span class="text-sm font-medium truncate">{{ selectedStore.sub_locality }}</span>
                </div>
                <button @click="toggleStoreDropdown"
                  class="absolute inset-y-0 right-0 flex items-center px-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400"
                    fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                <div v-if="showDropdown" class="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-50 max-h-60 w-full overflow-y-auto">
                  <div v-for="store in filteredStores" :key="store.wid"
                    class="p-2 cursor-pointer hover:bg-gray-50" @click="selectStore(store)">
                    <span class="text-sm">{{ store.sub_locality }}</span>
                  </div>
                </div>
              </div>

              <!-- Year Dropdown -->

              <!-- Start Month Dropdown -->
              <div class="sm:w-auto flex-1" style="max-width: 250px;">
                <v-select
                  v-model="selectedStartMonthYear"
                  :items="startMonthYearOptions"
                  label="Select Start Month"
                  class="start-month-filter"
                  :disabled="isGenerating"
                ></v-select>
              </div>

              <!-- End Month Dropdown -->
              <div class="sm:w-auto flex-1" style="max-width: 250px;">
                <v-select
                  v-model="selectedEndMonthYear"
                  :items="endMonthYearOptions"
                  label="Select End Month"
                  class="end-month-filter"
                  :disabled="isGenerating"
                ></v-select>
              </div>

              <!-- Generate Report Button -->
              <v-btn
                color="primary"
                @click="generateReport"
                :loading="isGenerating"
                class="px-6 h-[40px] w-full sm:w-auto"
              >
                <v-icon left>mdi-file-chart</v-icon>
                Generate Report
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <!-- Report Content -->
      <div v-if="hasData" class="space-y-6">
        <div class="bg-white rounded-lg shadow-sm overflow-hidden">
          <div class="overflow-x-auto">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Keyword</th>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Impressions</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(item, index) in reportData" :key="index">
                  <td class="px-6 py-4 whitespace-nowrap">{{ item.searchKeyword }}</td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ formatImpressions(item.insightsValue.value || item.insightsValue.threshold) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else-if="isGenerating" class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <v-skeleton-loader v-for="n in 4" :key="n" type="card" class="bg-white rounded-lg"></v-skeleton-loader>
      </div>
      <div v-else class="text-center py-12">
        <v-icon size="64" color="grey lighten-1">mdi-alert-circle-outline</v-icon>
        <p class="mt-4 text-gray-600">No data available</p>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, query, where, getDocs } from "firebase/firestore";
import { db, functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";

export default {
  name: "GmbSearchReport",
  data() {
    return {
      selectedStartMonthYear: null,
      selectedEndMonthYear: null,
      startMonthYearOptions: [],
      endMonthYearOptions: [],
      isGenerating: false,
      reportData: null,
      locationSearch: "",
      selectedStore: null,
      stores: [],
      showDropdown: false,
    };
  },
  computed: {
    hasData() {
      return this.reportData?.length;
    },
    filteredStores() {
      if (!this.locationSearch) return this.stores;
      const searchTerm = this.locationSearch.toLowerCase();
      return this.stores.filter((store) =>
        store.sub_locality?.toLowerCase().includes(searchTerm)
      );
    },
  },
  methods: {
    generateMonthYearOptions(isEndMonth = false) {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1; // Months are 0-indexed
      const options = [];
      const startMonthYear = this.selectedStartMonthYear ? this.selectedStartMonthYear.split('-') : null;

      for (let year = currentYear; year > currentYear - 3; year--) {
        for (let month = 1; month <= 12; month++) { // Iterate from January to December
          // Logic for disabling options
          if (isEndMonth) {
            if (!startMonthYear) {
              continue; // Skip if start month is not selected
            }
            const selectedStartMonth = parseInt(startMonthYear[0]);
            const selectedStartYear = parseInt(startMonthYear[1]);

            // Disable months before the selected start month
            if (year < selectedStartYear || (year === selectedStartYear && month < selectedStartMonth)) {
              continue; // Skip months before the selected start month
            }

            // Ensure end month does not exceed the current month
            if (year === currentYear && month > currentMonth) {
              continue; // Skip future months for end month
            }
          } else {
            // For start month, skip future months if the current month is not completed
            if (year === currentYear && month > currentMonth) {
              continue; // Skip future months for start month
            }
          }
          options.push({
            text: new Date(year, month - 1).toLocaleString("default", { month: "long" }) + ` ${year}`,
            value: `${month}-${year}`, // Format: month-year
          });
        }
      }

      // Sort options in reverse order by year and month
      options.sort((a, b) => {
        const [monthA, yearA] = a.value.split('-').map(Number);
        const [monthB, yearB] = b.value.split('-').map(Number);
        return yearB - yearA || monthB - monthA; // Sort by year (descending), then by month (descending)
      });

      return options;
    },
    async fetchStores() {
      try {
        const storeRef = collection(db, "users", this.currentUID, "fly_workspaces");
        const storeQuery = query(
          storeRef,
          where("admin", "==", this.WID),
          where("storeWorkspace", "==", true)
        );

        const querySnapshot = await getDocs(storeQuery);
        this.stores = querySnapshot.docs.map((docSnapshot) => ({
          ...docSnapshot.data(),
          wid: docSnapshot.id,
        }));
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    },
    toggleStoreDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    handleSearchInput() {
      this.showDropdown = true;
    },
    selectStore(store) {
      this.selectedStore = store;
      this.locationSearch = store.sub_locality;
      this.showDropdown = false;
    },
    async generateReport() {
      this.isGenerating = true;
      try {
        const generateSearchReport = httpsCallable(functions, "gmbSearchReportFunction");
        const [startMonth, startYear] = this.selectedStartMonthYear.split('-');
        const [endMonth, endYear] = this.selectedEndMonthYear.split('-');
        const payload = {   
          uid: this.currentUID,
          wid: this.selectedStore.wid,
          locationId: this.selectedStore.gmbProfile.name?.replace("locations/", ""),
          startYear: startYear,
          endYear: endYear,
          startMonth: startMonth,
          endMonth: endMonth,
        };
        const result = await generateSearchReport(payload);
        this.reportData = result.data?.searchKeywordsCounts || [];
        console.log(this.reportData, "this is report data");
      } catch (error) {
        console.error("Error generating report:", error);
      } finally {
        this.isGenerating = false;
      }
    },
    formatImpressions(value) {
      if (value <= 15) {
        return `${value} (approx.)`;
      }
      return value; // Return the value as is if greater than 15
    },
  },
  mounted() {
    this.startMonthYearOptions = this.generateMonthYearOptions();
    this.endMonthYearOptions = this.generateMonthYearOptions(true);
    this.fetchStores();
  },
  watch: {
    selectedStartMonthYear(newValue) {
      this.endMonthYearOptions = this.generateMonthYearOptions(true);
    },
  },
};
</script>

<style scoped>
/* Add any specific styles for this page */
</style>
