<template>
  <div
    v-if="
      $route.name !== 'lite' &&
      $route.name !== 'accept-invite' &&
      $route.name !== 'store-invitation-accepted'
    "
    :class="
      $route === '/connected-apps'
        ? 'relative z-[99999999]'
        : 'relative z-[99999999]'
    "
  >
    <v-navigation-drawer
      app
      width="270"
      v-model="drawer"
      class="z-50 white"
      v-if="isAuth"
      @input="$emit('input', drawer)"
    >
      <div
        class="justify-start pt-2 text-center pa-1 d-flex"
        style="height: 63px"
      >
        <img
          src="../assets/img/Ic_fly.png"
          width="100"
          height="50"
          style="object-fit: contain"
        />

        <div class="-ml-5">
          <v-icon v-if="currentWorkspaceLogo" color="black">mdi-close</v-icon>
          <v-list-item-avatar v-if="currentWorkspaceLogo" class="mr-2">
            <img
              :src="currentWorkspaceLogo"
              alt="Workspace Logo"
              class="h-6 w-6 rounded-full"
            />
          </v-list-item-avatar>
          <!-- If currentWorkspaceLogo is null, show the placeholder image -->
        </div>
      </div>

      <v-dialog v-model="showDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">
            Time to renew!<v-icon color="error">mdi-bell-ring-outline</v-icon>
            <v-btn
              v-if="showDialog"
              icon
              @click="closeDialog"
              class="close-dialog-btn"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="font-semibold">
            You have run out of credits. Please renew your subscription to keep
            posting!
          </v-card-text>
          <v-card-actions>
            <v-btn @click="closeDialog">Close</v-btn>
            <v-btn @click="renewSubscription" color="primary"
              >Renew<v-icon>mdi-creation</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider></v-divider>
      <workspace-menu></workspace-menu>

      <v-list dense nav class="pa-3 ml-3 my-2">
        <template v-for="(item, i) in menus">
          <v-list-item
            :class="active(item)"
            :key="i"
            @click="
              item.name === 'analytics' || item.name === 'gmb'
                ? toggleDropdown(item.name)
                : open(item)
            "
          >
            <v-list-item-avatar size="22" tile>
              <img v-if="active(item)" :src="item.icon" alt="icons" />
              <img v-else :src="item.icon2" alt="icons" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                :class="{
                  'text-[#7a58d5] text-md text-[15px]': active(item),
                  'text-whites text-md font-semibold': !active(item),
                }"
              >
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
            <v-icon
              v-if="item.name === 'analytics' || item.name === 'gmb'"
              class="ml-auto"
              color="black"
            >
              mdi-chevron-down
            </v-icon>
          </v-list-item>
          <template v-if="(item.name === 'analytics' && showAnalyticsDropdown) || (item.name === 'gmb' && showGMBDropdown)">
            <v-list-item
              v-for="(subItem, j) in item.subMenus"
              :key="subItem.name"
              :class="active(subItem)"
              @click="open(subItem)"
              class="ml-6"
            >
              <v-list-item-avatar size="22" tile>
                <img v-if="active(subItem)" :src="subItem.icon" alt="icons" />
                <img v-else :src="subItem.icon2" alt="icons" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="{
                    'text-[#7a58d5] text-md': active(subItem),
                    'text-whites text-md': !active(subItem),
                  }"
                >
                  {{ subItem.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      app
      width="60"
      class="z-50 hidden white md:block"
      v-if="!drawer && $route.path !== '/resources'"
      @input="$emit('input', drawer)"
    >
      <v-dialog v-model="showDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">
            <v-btn
              v-if="showDialog"
              icon
              @click="closeDialog"
              class="close-dialog-btn"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-dialog>

      <v-divider></v-divider>
      <v-list nav class="flex flex-col items-center">
        <template v-for="(item, i) in menus">
          <v-list-item
            :class="active(item)"
            class="pl-4"
            :key="i"
            @click="open(item)"
          >
            <v-list-item-avatar size="22" tile>
              <img v-if="active(item)" :src="item.icon" alt="icons" />
              <img v-else :src="item.icon2" alt="icons" />
            </v-list-item-avatar>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import hopscotch from "hopscotch";
import WorkspaceMenu from "./WorkspaceMenu.vue";
import { auth } from "@/utils/firebase.utils";
import { onSnapshot, doc, getDoc } from "firebase/firestore";
import { colUsers } from "@/utils/firebase.utils.js";

import "hopscotch/dist/css/hopscotch.min.css"; // Import Hopscotch CSS

export default {
  components: {
    WorkspaceMenu,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      drawer: true,
      showDialog: false,
      currentWorkspaceLogo: null,
      validLocation: false,
      showAnalyticsDropdown: true,
      showGMBDropdown: true,
      hasBoostPlan: false,
    };
  },
  watch: {
    value(val) {
      this.drawer = val;
    },
    authUser: {
      immediate: true,
      handler() {
        this.checkUserPlan();
      },
    },
  },
  computed: {
    menus() {
      const routePath = this.$route.path; // Get the current route path
      let list = [];
      // Admin routes
      if (this.checkUserPlan()) {
        this.validLocation = true;
      }
      const isAdminRoute =
        routePath === "/power-users" || routePath === "/help-cms";

      // Check user plan first
      
      let tempItems = [
        {
          name: "calendar",
          title: "Planner",
          icon: require("@/assets/img/plan_n.svg"),
          icon2: require("@/assets/img/plan_w.svg"),
          names: [""],
        },
        {
          name: "fly",
          title: "Posts",
          icon: require("@/assets/img/sidebar/posts_w.svg"),
          icon2: require("@/assets/img/sidebar/posts_n.svg"),
          names: ["fly", "error"],
        },
        {
          name: "analytics",
          title: "Analytics",
          icon: require("@/assets/img/sidebar/analytics_w.svg"),
          icon2: require("@/assets/img/sidebar/analytics_n.svg"),
          names: ["analytics"],
          subMenus: [
            {
              name: "dashboard",
              title: "Channels",
              icon: require("@/assets/img/sidebar/chl_n.svg"),
              icon2: require("@/assets/img/sidebar/channel_W.svg"),
              names: ["dashboard-new"],
            },
            // Conditionally include GMB if validLocation is true
            ...(this.validLocation
              ? [
                  // {
                  //   name: "gmb-analytics",
                  //   title: "GMB",
                  //   icon: require("@/assets/img/sidebar/chl_n.svg"),
                  //   icon2: require("@/assets/img/sidebar/channel_W.svg"),
                  //   names: ["Google Business Profile Analytics"],
                  // },
                ]
              : []),
            {
              name: "content-insights",
              title: "Content",
              icon: require("@/assets/img/sidebar/content_n.svg"),
              icon2: require("@/assets/img/sidebar/content_w.svg"),
              names: ["content-insights"],
            },
          ],
        },
        // Only include GMB menu if user has boost plan
        ...(this.hasBoostPlan ? [{
          name: "gmb",
          title: "GMB",
          icon: require("@/assets/img/review.png"),
          icon2: require("@/assets/img/review.png"),
          names: ["GMB"],
          subMenus: [
            {
              name: "locations",
              title: "Locations",
              icon: require("@/assets/img/sidebar/icons8-worldwide-location-50.png"),
              icon2: require("@/assets/img/sidebar/loc_g.svg"),
              names: ["locations"],
            },
            {
              name: "update-gmb-profile",
              title: "Profiles Strength",
              icon: require("@/assets/img/sidebar/pr_g.png"),
              icon2: require("@/assets/img/sidebar/pr_gw.svg"),
              names: ["Profiles"],
            },
            {
              name: "gmb-analytics",
              title: "GMB Stats ",
              icon: require("@/assets/img/sidebar/chl_n.svg"),
              icon2: require("@/assets/img/sidebar/channel_W.svg"),
              names: ["Google Business Profile Analytics"],
            },
            {
              name: "gmb-reviews",
              title: "Reviews",
              icon: require("@/assets/img/sidebar/reviews_g.png"),
              icon2: require("@/assets/img/sidebar/reviews_gw.svg"),
              names: ["GMB Reviews"],
            },
            {
              name: "gmb-reviews-report",
              title: "Reviews Insights",
              icon: require("@/assets/img/sidebar/content_n.svg"),
              icon2: require("@/assets/img/sidebar/content_w.svg"),
              names: ["Reviews Insights"],
            },
            {
              name: "gmb-competitor-analysis",
              title: "Competitor Analysis",
              icon: require("@/assets/img/sidebar/competitive_analysis.png"),
              icon2: require("@/assets/img/sidebar/competitive_analysis.png"),
              names: ["GMB Reviews"],
            },
            {
              name: "gmb-search-report",
              title: "Search Insights",
              icon: require("@/assets/img/sidebar/content_n.svg"),
              icon2: require("@/assets/img/sidebar/content_w.svg"),
              names: ["Search Insights"],
            },
          ],
        }] : []),
        // {
        //   name: "social-inbox",
        //   title: "Social Inbox",
        //   icon: require("@/assets/img/sidebar/social-inbox-p.png"),
        //   icon2: require("@/assets/img/sidebar/social-inbox.png"),
        //   names: ["social-inbox"],
        // },
        // {
        //   name: "gmb-competitor-analysis",
        //   title: "Competitor Analysis",
        //   icon: require("@/assets/img/sidebar/content_n.svg"),
        //   icon2: require("@/assets/img/sidebar/content_w.svg"),
        //   names: ["GMB Reviews"],
        // },

      ];

      if (this.currentUID == auth.currentUser.uid) {
        tempItems.push(
          {
            name: "connected-apps",
            title: "Social  Channels",
            icon: require("@/assets/img/sidebar/connect_w.svg"),
            icon2: require("@/assets/img/sidebar/connect_n.svg"),
            names: ["connected-apps"],
          },
          // {
          //   name: "locations",
          //   title: "Locations",
          //   icon: require("@/assets/img/sidebar/icons8-worldwide-location-50.png"),
          //   icon2: require("@/assets/img/sidebar/icons8-worldwide-location-50.png"),
          //   names: ["locations"],
          // }
        );
      }
      if (this.currentWorkspace && this.currentWorkspace?.role === "Client") {
        tempItems = [
          {
            name: "calendar",
            title: "Planner",
            icon: require("@/assets/img/plan_n.svg"),
            icon2: require("@/assets/img/plan_w.svg"),
            names: [""],
          },
          {
            name: "analytics",
            title: "Analytics",
            icon: require("@/assets/img/sidebar/analytics_w.svg"),
            icon2: require("@/assets/img/sidebar/analytics_n.svg"),
            names: ["analytics"],
            subMenus: [
              {
                name: "dashboard",
                title: "Channels",
                icon: require("@/assets/img/sidebar/chl_n.svg"),
                icon2: require("@/assets/img/sidebar/channel_W.svg"),
                names: ["dashboard-new"],
              },
              ...(this.validLocation
                ? [
                    {
                      name: "gmb-analytics",
                      title: "GMB",
                      icon: require("@/assets/img/sidebar/chl_n.svg"),
                      icon2: require("@/assets/img/sidebar/channel_W.svg"),
                      names: ["Google Business Profile Analytics"],
                    },
                  ]
                : []),
              {
                name: "content-insights",
                title: "Content",
                icon: require("@/assets/img/sidebar/content_n.svg"),
                icon2: require("@/assets/img/sidebar/content_w.svg"),
                names: ["content-insights"],
              },
            ],
          },
        ];
      }
      const generalItems = tempItems;

      // Only include these items on admin routes
      const adminItems = [
        {
          name: "admin",
          title: "Admin - Campaign",
          icon: require("@/assets/img/sidebar/campaign-userwhite.svg"),
          icon2: require("@/assets/img/sidebar/campaign-user.svg"),
          names: ["admin"],
        },
        {
          name: "help-cms",
          title: "Admin - Platforms",
          icon: require("@/assets/img/sidebar/admin.svg"),
          icon2: require("@/assets/img/sidebar/adminwhite.svg"),
          names: ["update-help-cms"],
        },
      ];

      if (this.isAdmin && isAdminRoute) {
        // If the user is an admin and on an admin route, show only admin items
        list = adminItems;
      } else if (!isAdminRoute) {
        // For all other routes, show the general items
        list = generalItems;
      }

      return list;
    },
  },
  created(){
    this.checkUserPlan();
  },
  methods: {
    async fetchLocationsConnectionStatus() {
      const workspaceRef = collection(
        db,
        "users",
        this.currentUID,
        "fly_workspaces"
      );
      try {
        const querySnapshot = await getDocs(workspaceRef);
        for (let doc of querySnapshot.docs) {
          const data = doc.data();
          if (
            doc.id !== "default" &&
            data.storeWorkspace &&
            data.status === "connected" &&
            data.connected_apps &&
            data.connected_apps.length > 0
          ) {
            return true;
          }
        }
        return false;
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    },
    async checkUserPlan() {
      const requiredPlan = process.env.VUE_APP_BROADCAST_PLAN || "boost";
      this.hasBoostPlan = this.authUser?.fly_plan === requiredPlan;
    },
    loadCurrentWorkspace() {
      const workspaceDocRef = doc(
        colUsers,
        this.currentUID,
        "fly_workspaces",
        this.WID
      );
      onSnapshot(
        workspaceDocRef,
        (doc) => {
          if (doc.exists()) {
            const workspaceData = doc.data();
            this.currentWorkspaceLogo = workspaceData.logo_original || "";
            this.currentWorkspaceName = workspaceData.name || "";
          } else {
            console.error("No such document!");
          }
        },
        (error) => {
          console.error("Error getting document:", error);
        }
      );
    },
    toggleAnalyticsDropdown() {
      this.showAnalyticsDropdown = !this.showAnalyticsDropdown;
    },
    active(item) {
      let { names } = item;
      let { name } = this.$route;
      let isActive = name == item.name || names.indexOf(name) != -1;
      return isActive ? "drawer__active text-orange-300" : "";
    },
    open({ name, title }) {
      if (this.$router.currentRoute.name !== name) {
        this.$router.push({ name }).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            console.error(err);
          }
        });
      }
      this.$root.$emit("tab-selected", title); // Emit the selected tab's title globally
      console.log("Tab selected:", title); // Log for debugging
    },
    closeDialog() {
      this.showDialog = false;
    },
    renewSubscription() {
      this.$router.push("/subscription");
      this.closeDialog();
    },
    toggleDropdown(name) {
      if (name === 'analytics') {
        this.showAnalyticsDropdown = !this.showAnalyticsDropdown;
      } else if (name === 'gmb') {
        this.showGMBDropdown = !this.showGMBDropdown;
      }
    },
  },
  async mounted() {
    this.loadCurrentWorkspace();
  },
};
</script>

<style lang="scss">
.v-navigation-drawer {
  .v-navigation-drawer__content {
    background-color: #ffffff !important; // Set background color to dark gray/blue
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .drawer-menu {
    height: calc(100vh - 70px);
  }
}

.drawer__active i {
  color: #7a58d5 !important;
}

.app-version {
  font-size: 10px;
  color: #666;
}

.drawer__active {
  padding: 0px 0px;
  background-color: #ebf1fe !important; // Apply conditional transparency
  color: white !important; // Ensures text color is white
}

:root {
  --tw-bg-opacity: 1; // Default background opacity set to 1 (fully opaque)
}

.drawer__active:hover {
  --tw-bg-opacity: 0.8; // Example: change opacity on hover
}

.text-orange-300 {
  color: #fdba74 !important; // Apply Tailwind CSS color for orange-300
}

.text-whites {
  color: #667790 !important;
  font-size: 1rem !important; // Apply Tailwind CSS text-md size
}

.text-md {
  font-size: 1rem !important; // Apply Tailwind CSS text-md size
}
</style>
