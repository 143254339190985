<template>
  <div class="circle-marker" :style="{ backgroundColor: color }">
    <span class="circle-number" v-if="number">{{ number }}</span>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    number: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
</script>

<style scoped>
.circle-marker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  border-radius: 50%; /* Makes it circular */
  border: 2px solid white; /* Optional: border for better visibility */
  position: relative; /* For positioning */
}

.circle-number {
  color: white; /* Text color */
  font-weight: bold;
  font-size: 14px; /* Adjust font size as needed */
}
</style>
